import PropTypes from 'prop-types';
import MultiJackpotElement from './multi_jackpot_element';

const MultiJackpotView = ({ tickers }) => {
  const sortedTickers = tickers.sort((a, b) => (parseInt(a.c?.dpt, 10) - parseInt(b.c?.dpt, 10)));

  return (
    sortedTickers.map((ticker) => (
      ticker?.c?.dpt ? <MultiJackpotElement ticker={ticker} /> : null
    ))
  );
};

MultiJackpotView.propTypes = {
  tickers: PropTypes.instanceOf(Array).isRequired,
};

export default MultiJackpotView;
