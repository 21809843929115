import { property, omit } from 'underscore';
import { removeJackpotTickers, removeLivecasinoTickers, removeBaccaratTickers } from 'CasinoGlobal/redux/tickers/action_creators';

import { ADD_GAMES, REMOVE_GAMES } from '../action_types';
import {
  registerJackpotTickers,
  unregisterJackpotTickers,
  registerLivecasinoTickers,
  unregisterLivecasinoTickers,
} from './message_handlers';

import { registerBaccaratTickers, unregisterBaccaratTickers } from './baccarat_handlers';

const liveCasinoTickerTypes = [
  'has_min_stake_limits_tickers',
  'has_max_stake_limits_tickers',
  'has_player_count_tickers',
  'has_seats_taken_out_of_total_tickers',
  'has_roulette_results_tickers',
  'has_lightning_roulette_results_tickers',
  'has_super_sic_bo_results_tickers',
  'has_dream_catcher_results_tickers',
  'has_monopoly_results_tickers',
  'has_lightning_dice_results_tickers',
  'has_bingo_tickers',
];

const newGamesOnly = (games, newGames) => (
  omit(newGames, Object.keys(games))
);

const getJackpotsIds = (games) => {
  const ids = Object.values(games)
    .filter(property(['tickers', 'has_jackpot_tickers']))
    .reduce(
      (previousValue, currentValue) => (
        currentValue.jackpotIds && currentValue.jackpotIds.length > 1
          ? previousValue.concat(currentValue.jackpotIds)
          : [
            ...previousValue,
            ...(currentValue.jackpotId ? [currentValue.jackpotId] : []),
          ]),
      [],
    );
  return [...new Set(ids)];
};

const filterLiveCasinoTickerGameIds = (games) => {
  const ids = Object.values(games)
    .filter((game) => liveCasinoTickerTypes.some((type) => game.tickers[type]))
    .map(property('id'));
  return [...new Set(ids)];
};

const filterBaccaratGameIds = (games) => {
  const baccaratTickerIds = Object.values(games)
    .filter((game) => game.tickers.has_baccarat_roads_tickers)
    .map((game) => (`${game.id}/${game.useWideTile ? '46' : '21'}`));
  return [...new Set(baccaratTickerIds)];
};

export default (store) => (next) => (action) => {
  const { 'casino/games': games } = store.getState();

  switch (action.type) {
    case ADD_GAMES:
    {
      registerJackpotTickers(getJackpotsIds(newGamesOnly(games, action.games)));
      registerLivecasinoTickers(filterLiveCasinoTickerGameIds(newGamesOnly(games, action.games)));
      registerBaccaratTickers(filterBaccaratGameIds(newGamesOnly(games, action.games)));
      break;
    }
    case REMOVE_GAMES:
    {
      const reducedGameIds = action.gamesIds.reduce((accum, gameId) => {
        const game = games[gameId];

        if (game.refCount === 1) {
          return {
            ...accum,
            [gameId]: game,
          };
        }

        return accum;
      }, {});

      const jackpotsIds = getJackpotsIds(
        reducedGameIds,
      );

      const lcTickerGameIds = filterLiveCasinoTickerGameIds(
        reducedGameIds,
      );

      const baccaratGameIds = filterBaccaratGameIds(
        reducedGameIds,
      );

      unregisterJackpotTickers(jackpotsIds);
      unregisterLivecasinoTickers(lcTickerGameIds);
      unregisterBaccaratTickers(baccaratGameIds);
      // Remove tickers from the state along with the game
      store.dispatch(removeJackpotTickers(jackpotsIds));
      store.dispatch(removeLivecasinoTickers(lcTickerGameIds));
      store.dispatch(removeBaccaratTickers(baccaratGameIds));
      break;
    }
    default:
      break;
  }

  next(action);
};
