import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { selectliveCasinoTickers, selectJackpot, selectJackpots } from 'casino-selectors/tickers';
import BingoInfoContainer from './bingo_info_container';

const BingoInfoRedux = ({
  game: {
    id: gameId, tickers: hasTickers, jackpotId, jackpotIds, largeImage,
  },
}) => {
  const jackpot = useSelector((state) => selectJackpot(state, jackpotId));
  const jackpots = useSelector((state) => selectJackpots(state, jackpotIds));
  const tickers = useSelector((state) => selectliveCasinoTickers(state, gameId));

  return tickers && (tickers[2] || tickers[15] || tickers[16] || tickers[17])
    && hasTickers.has_bingo_tickers ? (
      <BingoInfoContainer
        tickers={tickers}
        hasTickers={hasTickers}
        jackpotTicker={hasTickers.has_jackpot_tickers ? jackpot : null}
        multiJackpot={
          hasTickers.has_jackpot_tickers && largeImage && jackpotIds.length > 1 ? jackpots : null
        }
      />
    ) : null;
};

BingoInfoRedux.propTypes = {
  game: PropTypes.instanceOf(Object).isRequired,
};

export default BingoInfoRedux;
