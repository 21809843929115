/* eslint import/prefer-default-export: off */
import { ADD_STUDIOS, REMOVE_STUDIOS } from './action_types';

export const addStudios = (studios) => ({
  type: ADD_STUDIOS,
  studios,
});

export const removeStudios = (studiosIds) => ({
  type: REMOVE_STUDIOS,
  studiosIds,
});
