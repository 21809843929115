import PropTypes from 'prop-types';

import GameLogo from './game_logo';
import BingoInfo from './bingo_info';
import BingoTimer from './bingo_timer';

const BingoGameEnabled = ({ game }) => (
  <>
    {game.ribbon && <div className="casino-game__ribbon">{game.ribbon}</div>}
    <GameLogo game={game} />
    <BingoTimer game={game} />
    <BingoInfo game={game} />
  </>
);

BingoGameEnabled.propTypes = {
  game: PropTypes.instanceOf(Object).isRequired,
};

export default BingoGameEnabled;
