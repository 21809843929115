import PropTypes from 'prop-types';

const LiveCasinoNumberItemView = ({
  className,
  value,
  multiplier,
}) => (
  <li className={`casino-game-data__list-item ${className}`}>
    {value}
    {multiplier
    && (
      <span>
        {multiplier}
        x
      </span>
    )}
  </li>
);

LiveCasinoNumberItemView.propTypes = {
  className: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  multiplier: PropTypes.string,
};

LiveCasinoNumberItemView.defaultProps = {
  multiplier: '',
};

export default LiveCasinoNumberItemView;
