import PropTypes from 'prop-types';
import LiveCasinoNumberItemView from './live_casino_number_item_view';

const LiveCasinoOtherNumbers = ({ numbers, type }) => {
  const getColor = (item) => {
    if (type === 'lightning-dice') {
      if (item.split('x')[1]) return 'lightning-gold';
      return '';
    }
    if (type === 'sicbo') {
      if (item.endsWith('t')) return 'sicbo-triple';
      const number = parseInt(item, 10);
      if (number < 11) return 'sicbo-small';
      if (number > 10) return 'sicbo-large';
    }
    if (type === 'dreamcatcher') {
      return `dream-catcher-${item}`;
    }
    if (type === 'monopoly') {
      return `monopoly-${item}`;
    }
    if (type === 'crazy-time') {
      return `crazy-time-${item}`;
    }
    return item;
  };

  const mapping = (val) => {
    // Sicbo
    if (val.includes('t')) return val.replace(/t$/, '');
    // Monopoly
    if (val.includes('r')) return val.replace(/r$/, '');
    if (val === 'ch') return '?';
    // Crazy Time
    if (['ca', 'pa', 'fl', 'bo'].includes(val)) return '';

    return val;
  };

  return (
    <>
      {numbers.map((item) => (
        <LiveCasinoNumberItemView
          key={item}
          className={getColor(item)}
          value={mapping(item.split('x')[0])}
          multiplier={item.split('x')[1] || ''}
        />
      ))}
    </>
  );
};

LiveCasinoOtherNumbers.propTypes = {
  numbers: PropTypes.instanceOf(Array).isRequired,
  type: PropTypes.string.isRequired,
};

export default LiveCasinoOtherNumbers;
