import { memo, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { isEqual } from 'underscore';
import { selectGame } from 'casino-selectors/games';
import { useLoggedIn } from 'bv-hooks';
import { getFavoriteGames } from 'CasinoGlobal/redux/favorites/thunk';
import { selectFavorite } from '../../redux/favorites/selectors';
import GameContainer from './game_container';

const GameRedux = memo(({ gameId, gameInstance, ...rest }) => {
  const selectFavoritesById = useMemo(selectFavorite, []);
  const isFavorite = useSelector((state) => selectFavoritesById(state, gameId), isEqual);

  const game = useSelector((state) => selectGame(state, gameId), isEqual);
  const loggedIn = useLoggedIn();
  const dispatch = useDispatch();

  useEffect(() => {
    if (loggedIn) {
      dispatch(getFavoriteGames());
    }
  }, [loggedIn]);
  return <GameContainer {...rest} game={{ isFavorite, ...game, ...gameInstance }} />;
});

GameRedux.propTypes = {
  gameId: PropTypes.number.isRequired,
  gameInstance: PropTypes.instanceOf(Object),
};

GameRedux.defaultProps = {
  gameInstance: {},
};

export default GameRedux;
