import { memo } from 'react';
import PropTypes from 'prop-types';
import { Image, Icon } from 'bv-components';
import { useFeature, useLoggedIn } from 'bv-hooks';
import classNames from 'classnames';
import { useHistory } from 'react-router';
import { getGameInfoHref } from 'CasinoGlobal/helpers/game_href';
import GameLink from './components/game_link';
import GameVideo from './components/game_video';
import GamePostMessage from './components/game_post_message';
import FavoriteButton from './components/favorite_button';

const GameView = memo(({
  className,
  gameClassName,
  gameTitleClassName,
  imagePath,
  webpPath,
  innerComponent: GameInner,
  game,
  category,
  tracking,
  gameSearchCategoryId,
  onImgLoad,
  onImgError,
  scrollableRef,
  isVideoTile,
  callParent,
  parentSessionId,
  isSvgImage,
  largeImage,
  isLazyImage,
  showFavorite,
}) => {
  const loggedIn = useLoggedIn();

  const [favoritesEnabled] = useFeature('favourite_casino_games');
  const [gameInfoEnabled] = useFeature('casino_game_info_page');
  const showInfoIcon = gameInfoEnabled && !loggedIn && game.hasGameInfo;

  const history = useHistory();
  const infoUrl = getGameInfoHref(game);

  const handleTitleClick = (event) => {
    event.preventDefault();
    event.stopPropagation();

    history.push(infoUrl);
  };

  const casinoGame = (
    <div className={gameClassName}>
      <div className="casino-game__img">
        <Image
          className="casino-game__bg"
          src={imagePath}
          webpSrc={webpPath}
          onLoad={onImgLoad}
          onError={onImgError}
          scrollableRef={scrollableRef}
          webp={!isSvgImage}
          isLazyImage={isLazyImage}
        />
      </div>
      {showFavorite && loggedIn && favoritesEnabled && (
        <FavoriteButton
          gameId={game.id}
          isFavorite={game.isFavorite}
        />
      )}
      <GameInner game={{ ...game, largeImage }} />
    </div>
  );

  const GameAction = callParent ? GamePostMessage : GameLink;

  const gameTitleClasses = classNames(gameTitleClassName, {
    'is-no-icon': !showInfoIcon,
  });

  return (
    <li className={className}>
      <GameAction
        game={game}
        category={category}
        gameSearchCategoryId={gameSearchCategoryId}
        tracking={tracking}
        callParent={callParent}
        parentSessionId={parentSessionId}
      >
        {isVideoTile ? (
          <div className="casino-game-video">
            <GameVideo game={game} scrollableRef={scrollableRef} />
            {casinoGame}
          </div>
        ) : (
          casinoGame
        )}
        <div className={gameTitleClasses} onClick={gameInfoEnabled ? handleTitleClick : null}>
          {showInfoIcon && <Icon id="information" className="casino-game-title__info" />}
          <span className="casino-game-title__text">
            {game.title}
          </span>
        </div>
      </GameAction>
    </li>
  );
});

GameView.propTypes = {
  className: PropTypes.string,
  gameClassName: PropTypes.string.isRequired,
  gameTitleClassName: PropTypes.string.isRequired,
  imagePath: PropTypes.string.isRequired,
  webpPath: PropTypes.string,
  innerComponent: PropTypes.func.isRequired,
  game: PropTypes.instanceOf(Object).isRequired,
  category: PropTypes.string,
  gameSearchCategoryId: PropTypes.number,
  onImgLoad: PropTypes.func.isRequired,
  onImgError: PropTypes.func.isRequired,
  scrollableRef: PropTypes.instanceOf(Object),
  isVideoTile: PropTypes.bool.isRequired,
  callParent: PropTypes.string.isRequired,
  parentSessionId: PropTypes.string.isRequired,
  isSvgImage: PropTypes.bool.isRequired,
  largeImage: PropTypes.bool.isRequired,
  isLazyImage: PropTypes.bool,
  tracking: PropTypes.instanceOf(Object).isRequired,
  showFavorite: PropTypes.bool.isRequired,
};

GameView.defaultProps = {
  webpPath: null,
  scrollableRef: null,
  gameSearchCategoryId: null,
  className: null,
  category: null,
  isLazyImage: true,
};

GameView.displayName = 'GameView';

export default GameView;
