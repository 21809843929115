import PropTypes from 'prop-types';

const JackpotTickerView = ({ prefix, value }) => (
  <div className="casino-game__ticker">
    <div className="bvs-ellipsis">
      <span className="c-symbol c-preffix">{prefix}</span>
      {value}
    </div>
  </div>
);

JackpotTickerView.propTypes = {
  prefix: PropTypes.string,
  value: PropTypes.string.isRequired,
};

JackpotTickerView.defaultProps = {
  prefix: '',
};

export default JackpotTickerView;
