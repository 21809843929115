import PropTypes from 'prop-types';

const LiveCasinoNumbersView = ({
  logoClassName,
  numbers,
  innerComponent: TickerInner,
  type,
}) => {
  if (!TickerInner || !numbers.length) {
    return null;
  }

  return (
    <div className={`casino-game-data casino-game-data--${type} ${logoClassName}`}>
      <ul className="casino-game-data__list">
        {numbers
          && <TickerInner numbers={numbers} type={type} />}
      </ul>
    </div>
  );
};

LiveCasinoNumbersView.propTypes = {
  logoClassName: PropTypes.string,
  numbers: PropTypes.instanceOf(Array).isRequired,
  innerComponent: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
};

LiveCasinoNumbersView.defaultProps = {
  logoClassName: '',
};

export default LiveCasinoNumbersView;
