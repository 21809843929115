import PropTypes from 'prop-types';
import LiveCasinoNumberItemView from './live_casino_number_item_view';

const reds = [1, 3, 5, 7, 9, 12, 14, 16, 18, 19, 21, 23, 25, 27, 30, 32, 34, 36];
const blacks = [2, 4, 6, 8, 10, 11, 13, 15, 17, 20, 22, 24, 26, 28, 29, 31, 33, 35];

const LiveCasinoRoulette = ({ numbers }) => {
  const getColor = (item) => {
    if (item.split('x')[1]) return 'roulette-gold';
    const number = parseInt(item.split('x')[0], 10);
    if (reds.includes(number)) return 'roulette-red';
    if (blacks.includes(number)) return 'roulette-black';
    return 'roulette-green';
  };

  return (
    <>
      {numbers.map((item) => (
        <LiveCasinoNumberItemView
          key={item}
          className={getColor(item)}
          value={item.split('x')[0]}
          multiplier={item.split('x')[1]}
        />
      ))}
    </>
  );
};

LiveCasinoRoulette.propTypes = {
  numbers: PropTypes.instanceOf(Array).isRequired,
};

export default LiveCasinoRoulette;
