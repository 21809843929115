import { omit } from 'underscore';
import {
  SET_JACKPOT_TICKER,
  UPDATE_JACKPOT_TICKER,
  REMOVE_JACKPOT_TICKERS,
  SET_LIVECASINO_TICKER,
  UPDATE_LIVECASINO_TICKER,
  REMOVE_LIVECASINO_TICKERS,
  SET_BACCARAT_TICKER,
  REMOVE_BACCARAT_TICKERS,
} from './action_types';

export const initialState = {
  jackpotTickers: {},
  liveCasinoTickers: {},
  baccaratTickers: {},
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_JACKPOT_TICKER:
    {
      const { data: { id, ticker } } = action;

      return {
        ...state,
        jackpotTickers: {
          ...state.jackpotTickers,
          [id]: ticker,
        },
      };
    }
    case UPDATE_JACKPOT_TICKER:
    {
      const { data: { id, value } } = action;

      return {
        ...state,
        jackpotTickers: {
          ...state.jackpotTickers,
          [id]: {
            ...state.jackpotTickers[id],
            v: value,
          },
        },
      };
    }
    case REMOVE_JACKPOT_TICKERS:
      return {
        ...state,
        jackpotTickers: omit(state.jackpotTickers, action.jackpotsIds),
      };
    case SET_LIVECASINO_TICKER:
    {
      const { data: { id, type, ticker } } = action;
      return {
        ...state,
        liveCasinoTickers: {
          ...state.liveCasinoTickers,
          [id]: {
            ...state.liveCasinoTickers[id],
            [type]: ticker,
          },
        },
      };
    }
    case UPDATE_LIVECASINO_TICKER:
    {
      const { data: { id, type, value } } = action;
      return {
        ...state,
        liveCasinoTickers: {
          ...state.liveCasinoTickers,
          [id]: {
            ...state.liveCasinoTickers[id],
            [type]: {
              ...state.liveCasinoTickers[id][type],
              v: value,
            },
          },
        },
      };
    }
    case REMOVE_LIVECASINO_TICKERS:
      return {
        ...state,
        liveCasinoTickers: omit(state.liveCasinoTickers, action.gamesIds),
      };
    case SET_BACCARAT_TICKER:
    {
      const { data: { id, ticker } } = action;
      return {
        ...state,
        baccaratTickers: {
          ...state.baccaratTickers,
          [id]: ticker,
        },
      };
    }
    case REMOVE_BACCARAT_TICKERS:
      return {
        ...state,
        baccaratTickers: omit(state.baccaratTickers, action.gamesIds),
      };
    default:
      return state;
  }
};
