import {
  STORE_FILTER_GROUPS,
  STORE_LOBBY_TYPES,
  SELECT_FILTER_TAG,
  DESELECT_FILTER_TAG,
  FETCH_FILTER_GROUPS,
  STORE_FILTER_RESULT,
  RESET_ALL,
  STORE_FILTER_TAGS_IN_URL,
} from './action_types';

export const storeFilterGroups = (filterGroups, lobbyTag) => ({
  type: STORE_FILTER_GROUPS,
  filterGroups,
  lobbyTag,
});
export const storeLobbyTypes = (lobbyTag) => ({
  type: STORE_LOBBY_TYPES,
  lobbyTag,
});

export const fetchFilterGroups = () => ({
  type: FETCH_FILTER_GROUPS,
});

export const storeFilterTagsInUrl = (filterTagIds) => ({
  type: STORE_FILTER_TAGS_IN_URL,
  filterTagIds,
});

export const selectFilterTag = (filterTagId, lobbyTag) => ({
  type: SELECT_FILTER_TAG,
  filterTagId,
  lobbyTag,
});

export const deselectFilterTag = (filterTagId, lobbyTag) => ({
  type: DESELECT_FILTER_TAG,
  filterTagId,
  lobbyTag,
});

export const storeFilterResult = (filterResult, lobbyTag) => ({
  type: STORE_FILTER_RESULT,
  filterResult,
  lobbyTag,
});

export const resetAll = (lobbyTag) => ({
  type: RESET_ALL,
  lobbyTag,
});
