import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { format } from 'bv-helpers/number';
import BingoInfoView from './bingo_info_view';

const decimalVal = (dec) => (dec % 1 > 0 ? 2 : 0);
const formatValue = (val) => {
  const value = parseFloat(val);
  const formattedValue = format(
    value.toFixed(decimalVal(value)),
  );
  return value ? formattedValue : '';
};

const BingoInfoContainer = ({
  tickers, hasTickers, jackpotTicker, multiJackpot,
}) => {
  const [gamePrize, setGamePrize] = useState('');
  const [cardPrice, setCardPrice] = useState('');
  const [jackpot, setJackpot] = useState('');
  const [gamePrefix, setGamePrefix] = useState('');
  const [cardPrefix, setCardPrefix] = useState('');
  const [jackpotPrefix, setJackpotPrefix] = useState('');
  const [playersCount, setPlayersCount] = useState('');

  const setValue = () => {
    if (hasTickers.has_bingo_tickers && tickers[2]) {
      setPlayersCount(tickers[2].v);
    }
    if (hasTickers.has_bingo_tickers && tickers[15]) {
      const ticker = tickers[15];
      const prizeType = ticker.c?.pt;
      switch (prizeType) {
        case '1': {
          setGamePrefix(ticker.cs);
          setGamePrize(formatValue(ticker.v));
          break;
        }
        case '2': {
          const value = `${ticker.v} ${ticker.c?.d}`;
          setGamePrize(value || '');
          break;
        }
        default: break;
      }
    }
    if (hasTickers.has_bingo_tickers && tickers[16]) {
      setCardPrefix(tickers[16].cs);
      const value = parseFloat(tickers[16].v);
      const formattedValue = format(
        value.toFixed(decimalVal(value)),
      );
      setCardPrice(!Number.isNaN(value) ? formattedValue : '');
    }
    if (hasTickers.has_bingo_tickers && tickers[17]) {
      const ticker = tickers[17];
      const prizeType = ticker.c?.pt;
      switch (prizeType) {
        case '1': {
          setJackpotPrefix(ticker.cs);
          setJackpot(formatValue(ticker.v));
          break;
        }
        case '3':
          setJackpot(ticker.v ? ticker.v : '');
          break;
        default: break;
      }
    }
  };

  useEffect(() => {
    setValue();
  }, [tickers]);

  return (
    <BingoInfoView
      gamePrefix={gamePrefix}
      gamePrize={gamePrize}
      cardPrefix={cardPrefix}
      cardPrice={cardPrice}
      jackpotPrefix={jackpotPrefix}
      jackpot={jackpot}
      playersCount={playersCount}
      progressiveJackpot={jackpotTicker}
      multiJackpot={multiJackpot}
    />
  );
};

BingoInfoContainer.defaultProps = {
  hasTickers: [],
  tickers: [],
  jackpotTicker: null,
  multiJackpot: null,
};

BingoInfoContainer.propTypes = {
  hasTickers: PropTypes.instanceOf(Array),
  tickers: PropTypes.instanceOf(Array),
  jackpotTicker: PropTypes.instanceOf(Object),
  multiJackpot: PropTypes.instanceOf(Array),
};

export default BingoInfoContainer;
