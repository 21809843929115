import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { selectJackpot } from 'casino-selectors/tickers';
import JackpotTickerContainer from './jackpot_ticker_container';

const JackpotTickerRedux = ({ jackpotId }) => {
  const ticker = useSelector((state) => selectJackpot(state, jackpotId));
  if (!ticker) return null;

  return (
    <JackpotTickerContainer
      ticker={ticker}
    />
  );
};

JackpotTickerRedux.propTypes = {
  jackpotId: PropTypes.number,
};

JackpotTickerRedux.defaultProps = {
  jackpotId: null,
};

export default JackpotTickerRedux;
