import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { formatTimer } from 'CasinoGlobal/helpers';
import BingoTimerView from './bingo_timer_view';

const BingoTimerContainer = ({ tickers }) => {
  const [timeRemaining, setTimeRemaining] = useState('');
  const [underFiveMins, setUnderFiveMins] = useState(false);

  useEffect(() => {
    const total = parseInt(tickers[14].v, 10);
    const start = (new Date().getTime());
    const interval = setInterval(() => {
      const now = (new Date().getTime());
      const elapsed = now - start;
      const seconds = total - Math.floor(elapsed / 1000);
      if (seconds > 0) {
        setTimeRemaining(formatTimer(seconds));
        setUnderFiveMins(seconds <= 300);
      } else {
        clearInterval(interval);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [tickers[14].v]);

  return (
    <BingoTimerView
      timeRemaining={timeRemaining}
      highlight={underFiveMins}
    />
  );
};

BingoTimerContainer.defaultProps = {
  tickers: [],
};

BingoTimerContainer.propTypes = {
  tickers: PropTypes.instanceOf(Array),
};

export default BingoTimerContainer;
