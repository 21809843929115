import { schema } from 'normalizr';

const studio = new schema.Entity('studios');

export default new schema.Entity('games', {
  studio,
}, {
  processStrategy: ({
    ribbon, tileStyle, useAnimation, ...rest
  }) => ({ ...rest }),
});
