import { createContext } from 'react';

export default createContext({
  slug: 'casino',
  embedded: false,
  ownOverlay: false,
  hideHeader: false,
  parentSessionId: null,
  gameSessionId: null,
});
