import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { selectStudio } from 'casino-selectors/studios';
import { selectliveCasinoTickers } from 'casino-selectors/tickers';
import LiveCasinoNumbersContainer from './live_casino_numbers_container';

const LiveCasinoNumbersRedux = ({
  game: { id: gameId, tickers: hasTickers, studio: studioId },
}) => {
  const tickers = useSelector((state) => selectliveCasinoTickers(state, gameId));
  const studio = useSelector((state) => selectStudio(state, studioId));

  return tickers
    ? (
      <LiveCasinoNumbersContainer
        tickers={tickers}
        studio={studio}
        hasTickers={hasTickers}
      />
    ) : null;
};

LiveCasinoNumbersRedux.propTypes = {
  game: PropTypes.instanceOf(Object).isRequired,
};

export default LiveCasinoNumbersRedux;
