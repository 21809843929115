import { v as bvVar } from 'bv';
import { guard } from 'bv-services/features';
import { getJSON, postJSON, getJSONWithPreload } from 'bv-fetch';
import { accountNumber, loggedIn } from 'bv-helpers/session';
import { getTag } from './helpers/config';

const currentLocale = bvVar('locale');

const filterHiddenCategories = async (categories) => {
  const hiddenCasinoEnabled = await guard('hidden-casino-category');
  return hiddenCasinoEnabled ? categories : categories.filter((category) => !category.hidden);
};

const presentCatalog = async ({ categories, ...rest }) => ({
  ...rest,
  categories: await filterHiddenCategories(categories),
});

export const fetchCatalogJson = () => (
  getJSONWithPreload('/bv_api/casino/catalog', {
    c: currentLocale,
    games_list_component: getTag(),
  }).then(presentCatalog)
);

export const fetchComponentsJson = (category, suppliers) => (
  getJSON(`/api/casino/categories/${category}`, {
    c: currentLocale,
    l: accountNumber(),
    category,
    suppliers,
    games_list_component: getTag(),
  })
);

export const fetchGameDetailsJson = (gameId) => (
  getJSON(`/bv_api/casino/games/${gameId}`)
);

export const fetchLaunchParametersJson = (game, params = {}) => (
  postJSON(`/games/${game}/launch_by_inner_game_id`, params)
);

export const fetchPersonalizedGamesJson = (type) => (
  getJSON(`/api/casino/${type}`, { c: currentLocale })
);

// TODO: Api should be split, a simple change here is affecting 3 bundles
// This method should not needed for launch for example
// Tree shaking or split into different modules will work
export const fetchSearchResult = (term, page) => (
  getJSON('/api/casino/search', {
    search_term: term,
    c: currentLocale,
    l: loggedIn(),
    page,
  })
);

export const postPause = () => (
  postJSON('/api/casino/gaming_pause', {})
);

export const fetchFTPGamesJson = (gameVersionId) => (
  getJSON(`/api/casino/ftp_games/${gameVersionId}`, {})
);

export const fetchCTCProgress = (params) => (
  getJSON('api/ctc_progress', { codeType: 'CASINO', ...params })
);

export const getFilteredGames = (filterTagsIdsArr, page) => getJSON('/api/casino/filter/filter_games', { filtertag_ids: filterTagsIdsArr, page });
