import PropTypes from 'prop-types';
import { useJackpotAnimation } from '../../hooks';
import JackpotTickerView from './jackpot_ticker_view';

const JackpotTickerContainer = ({ ticker }) => {
  const value = useJackpotAnimation(ticker);

  return (
    <JackpotTickerView
      prefix={ticker.c.cs}
      value={value}
    />
  );
};

JackpotTickerContainer.propTypes = {
  ticker: PropTypes.instanceOf(Object).isRequired,
};

export default JackpotTickerContainer;
