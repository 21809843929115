import { v as bvVar } from 'bv';

const DEFAULT_GAMES_SLUG = 'casino';

export const { slug: defaultSlug = DEFAULT_GAMES_SLUG } = bvVar('casinoLobbies').find(
  ({ tag }) => tag === 'default',
) || {};

// For GameInfoHref (game info page) we use the default slug, to have general game urls for SEO
// For getGameLaunchHref (game launch url) we use the real slug, to avoid react re-renders
export const getGameInfoHref = ({ id, titleURL }, slug = defaultSlug) => (
  `/${slug}/games/${id}-${titleURL}`
);

export const getGameLaunchHref = (game, slug) => (
  `${getGameInfoHref(game, slug)}/launch`
);
