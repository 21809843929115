/* eslint import/prefer-default-export: off */
import { ADD_GAMES, REMOVE_GAMES } from './action_types';

export const addGames = (games) => ({
  type: ADD_GAMES,
  games,
});

export const removeGames = (gamesIds) => ({
  type: REMOVE_GAMES,
  gamesIds,
});
