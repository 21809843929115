import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'underscore';
import classnames from 'classnames';
import { addFavoriteGame, removeFavoriteGame } from 'CasinoGlobal/redux/favorites/thunk';

const FavoriteButton = ({
  gameId,
  isFavorite,
  addFavorite,
  removeFavorite,
}) => {
  const handleClick = (event) => {
    event.preventDefault();
    event.stopPropagation();

    if (isFavorite) {
      removeFavorite(gameId);
    } else {
      addFavorite(gameId);
    }
  };

  return (
    <div className={classnames('casino-game-favourite', { 'is-animating': isFavorite })} onClick={handleClick}>
      <div
        className={classnames('casino-game-favourite__icon', {
          'is-active': isFavorite,
        })}
      />
      <div className="casino-game-favourite__animated">
        <div className="casino-game-favourite__animated-bottom" />
        <div className="casino-game-favourite__animated-middle" />
        <div className="casino-game-favourite__animated-top" />
      </div>
    </div>
  );
};

FavoriteButton.propTypes = {
  gameId: PropTypes.string.isRequired,
  isFavorite: PropTypes.bool.isRequired,
  addFavorite: PropTypes.func.isRequired,
  removeFavorite: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  addFavorite: compose(dispatch, addFavoriteGame),
  removeFavorite: compose(dispatch, removeFavoriteGame),
});

export default connect(null, mapDispatchToProps)(FavoriteButton);
