/* eslint import/prefer-default-export: off */
import {
  ADD_FAVORITES, ADD_FAVORITE, REMOVE_FAVORITE, FETCH_FAVORITES,
} from './action_types';

export const addFavorites = (gameIds) => ({
  type: ADD_FAVORITES,
  gameIds,
});

export const addFavorite = (gameId) => ({
  type: ADD_FAVORITE,
  gameId,
});

export const removeFavorite = (gameId) => ({
  type: REMOVE_FAVORITE,
  gameId,
});

export const fetchFavorites = () => ({
  type: FETCH_FAVORITES,
});
