import PropTypes from 'prop-types';
import classNames from 'classnames';
import BingoJackpot from './bingo_jackpot';
import BingoProgressiveJackpot from './bingo_progressive_jackpot';
import MultiJackpot from '../multi_jackpot';

const BingoInfoView = ({
  gamePrefix,
  gamePrize,
  cardPrefix,
  cardPrice,
  jackpotPrefix,
  jackpot,
  playersCount,
  progressiveJackpot,
  multiJackpot,
}) => {
  const validDropPots = multiJackpot
    && (multiJackpot.length > 1) && multiJackpot.some((e) => e?.c?.dpt);

  const wrapperClass = classNames('casino-game__bingo-info', {
    'casino-game__bingo-info-three': (jackpot === '' && progressiveJackpot === null) || validDropPots,
  });

  return (
    <>
      {validDropPots && (
        <div className="casino-game__multi-jackpots">
          <div className="casino-game__multi-jackpot-droppots" />
          <MultiJackpot tickers={multiJackpot} />
        </div>
      )}
      <div className={wrapperClass}>
        {gamePrize !== '' && (
          <span className="casino-game__bingo-info-prize">
            <span className="casino-game__bingo-info-icon" />
            <span className="c-symbol c-preffix">{gamePrefix}</span>
            {gamePrize}
          </span>
        )}
        {cardPrice !== '' && (
          <span className="casino-game__bingo-info-ticket">
            <span className="casino-game__bingo-info-icon" />
            <span className="c-symbol c-preffix">{cardPrefix}</span>
            {cardPrice}
          </span>
        )}
        {jackpot !== '' && !progressiveJackpot && (
          <BingoJackpot
            jackpot={jackpot}
            jackpotPrefix={jackpotPrefix}
          />
        )}
        {progressiveJackpot !== null && !validDropPots && (
          <BingoProgressiveJackpot ticker={progressiveJackpot} />
        )}
        {playersCount !== '' && (
          <span className="casino-game__bingo-info-seats">
            <span className="casino-game__bingo-info-icon" />
            {playersCount}
          </span>
        )}
      </div>
    </>
  );
};

BingoInfoView.propTypes = {
  gamePrefix: PropTypes.instanceOf(String),
  gamePrize: PropTypes.instanceOf(String),
  cardPrefix: PropTypes.instanceOf(String),
  cardPrice: PropTypes.instanceOf(String),
  jackpotPrefix: PropTypes.instanceOf(String),
  jackpot: PropTypes.instanceOf(String),
  playersCount: PropTypes.instanceOf(String),
  progressiveJackpot: PropTypes.instanceOf(Object),
  multiJackpot: PropTypes.instanceOf(Array),
};

BingoInfoView.defaultProps = {
  gamePrefix: '',
  gamePrize: '',
  cardPrefix: '',
  cardPrice: '',
  jackpotPrefix: '',
  jackpot: '',
  playersCount: '',
  progressiveJackpot: null,
  multiJackpot: null,
};

export default BingoInfoView;
