import { v as bvVar } from 'bv';
import { getCurrencyCode } from 'bv-helpers/price';

import { registerHandler, unregisterHandler } from 'event-bus';
import {
  setJackpotTicker,
  updateJackpotTicker,
  setLivecasinoTicker,
  updateLivecasinoTicker,
} from 'CasinoGlobal/redux/tickers/action_creators';

const { store } = window.reduxState;
const jackpotHandlers = {};
const liveCasinoHandlers = {};

const jackpotTickerHandler = (id) => {
  jackpotHandlers[id] = jackpotHandlers[id] || ((err, { body: data }) => {
    if (data && data.v) {
      if (data.c) {
        store.dispatch(setJackpotTicker({ id, ticker: data }));
      } else if (store.getState()['casino/tickers'].jackpotTickers[id]) {
        store.dispatch(updateJackpotTicker({ id, value: data.v }));
      }
    }
  });

  return jackpotHandlers[id];
};

const liveCasinoTickerHandler = (id) => {
  liveCasinoHandlers[id] = liveCasinoHandlers[id] || ((err, { body: data }) => {
    if (Array.isArray(data) && data.length) {
      data.forEach((ticker) => {
        if (ticker.cs || ticker.c || !(store.getState()['casino/tickers'].liveCasinoTickers[id])) {
          store.dispatch(setLivecasinoTicker({ id, type: ticker.k, ticker }));
        } else {
          store.dispatch(updateLivecasinoTicker({ id, type: ticker.k, value: ticker.v }));
        }
      });
    }
  });

  return liveCasinoHandlers[id];
};

const withJackpotId = (fn) => (jackpotId) => {
  if (jackpotId) {
    fn(
      `/c/casinotickersjackpot/${bvVar('brandId')}/${getCurrencyCode()}/${jackpotId}`,
      jackpotTickerHandler(jackpotId),
    );
  }
};

const withGameId = (fn) => (gameId) => {
  fn(
    `/c/casinotickerssimple/${bvVar('brandId')}/${getCurrencyCode()}/${gameId}`,
    liveCasinoTickerHandler(gameId),
  );
};

const forEachGame = (fn) => (ids) => {
  ids.forEach(fn);
};

export const registerJackpotTickers = forEachGame(withJackpotId(registerHandler));
export const unregisterJackpotTickers = forEachGame(withJackpotId(unregisterHandler));
export const registerLivecasinoTickers = forEachGame(withGameId(registerHandler));
export const unregisterLivecasinoTickers = forEachGame(withGameId(unregisterHandler));
