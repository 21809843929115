import {
  STORE_FILTER_GROUPS,
  STORE_LOBBY_TYPES,
  SELECT_FILTER_TAG,
  DESELECT_FILTER_TAG,
  FETCH_FILTER_GROUPS,
  STORE_FILTER_RESULT,
  RESET_ALL,
  STORE_FILTER_TAGS_IN_URL,
} from './action_types';

export const initialState = {
  filterGroups: [],
  isFetching: false,
  selectedFilterTags: {},
  filterTagsInUrl: [],
  isResultPage: false,
  filterResult: {},
  currentLobby: '',
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case STORE_FILTER_GROUPS:
      return {
        ...state,
        filterGroups: action.filterGroups,
        isFetching: false,
        currentLobby: action.lobbyTag,
        selectedFilterTags: {
          ...state.selectedFilterTags,
          [action.lobbyTag]: state.selectedFilterTags[action.lobbyTag]
            ? [...state.selectedFilterTags[action.lobbyTag]]
            : [],
        },
        filterResult: {
          ...state.filterResult,
          [action.lobbyTag]: state.filterResult[action.lobbyTag]
            ? { ...state.filterResult[action.lobbyTag] }
            : { count: 0 },
        },
      };
    case STORE_LOBBY_TYPES:
      return {
        ...state,
        currentLobby: action.lobbyTag,
        selectedFilterTags: {
          ...state.selectedFilterTags,
          [action.lobbyTag]: [],
        },
        filterResult: {
          ...state.filterResult,
          [action.lobbyTag]: { count: 0 },
        },
      };
    case FETCH_FILTER_GROUPS:
      return { ...state, isFetching: true };
    case STORE_FILTER_TAGS_IN_URL:
      return {
        ...state,
        filterTagsInUrl: [...action.filterTagIds],
        isResultPage: true,
      };
    case SELECT_FILTER_TAG:
      return {
        ...state,
        selectedFilterTags: {
          ...state.selectedFilterTags,
          [action.lobbyTag]: Array.isArray(action.filterTagId)
            ? [...new Set([
              ...state.selectedFilterTags[action.lobbyTag],
              ...action.filterTagId],
            )]
            : [...state.selectedFilterTags[action.lobbyTag], action.filterTagId],
        },
      };
    case DESELECT_FILTER_TAG:
      return {
        ...state,
        selectedFilterTags: {
          ...state.selectedFilterTags,
          [action.lobbyTag]: Array.isArray(action.filterTagId)
            ? state.selectedFilterTags[action.lobbyTag].filter(
              (el) => !(new Set(action.filterTagId)).has(el))
            : state.selectedFilterTags[action.lobbyTag].filter((id) => id !== action.filterTagId),
        },
      };
    case STORE_FILTER_RESULT:
      return {
        ...state,
        filterResult: {
          ...state.filterResult,
          [action.lobbyTag]: { ...action.filterResult },
        },
      };
    case RESET_ALL:
      return {
        ...state,
        selectedFilterTags: {
          ...state.selectedFilterTags,
          [action.lobbyTag]: [],
        },
        filterTagsInUrl: [],
        filterResult: {
          ...state.filterResult,
          [action.lobbyTag]: { count: 0 },
        },
      };
    default:
      return state;
  }
};
