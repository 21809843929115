import PropTypes from 'prop-types';

const LiveCasinoTableInfoView = ({
  minprefix,
  minstake,
  maxprefix,
  maxstake,
  seats,
}) => (
  <div className="casino-game__info">
    <span>
      {minstake !== '' && (
        <>
          <span className="c-symbol c-preffix">{minprefix}</span>
          {minstake}
        </>
      )}
      {(minstake !== '' || maxstake !== '') && ' - '}
      {maxstake !== '' && (
        <>
          <span className="c-symbol c-preffix">{maxprefix}</span>
          {maxstake}
        </>
      )}
    </span>
    {seats !== '' && (
    <span className="casino-game__info-seats">{seats}</span>
    )}
  </div>
);

LiveCasinoTableInfoView.propTypes = {
  minprefix: PropTypes.string,
  minstake: PropTypes.string,
  maxprefix: PropTypes.string,
  maxstake: PropTypes.string,
  seats: PropTypes.string,
};

LiveCasinoTableInfoView.defaultProps = {
  minprefix: '',
  minstake: '',
  maxprefix: '',
  maxstake: '',
  seats: '',
};

export default LiveCasinoTableInfoView;
