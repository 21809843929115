import PropTypes from 'prop-types';

import GameLogo from './game_logo';

import JackpotTicker from './jackpot_ticker';

const GameEnabled = ({ game, studio }) => (
  <>
    {game.ribbon && <div className="casino-game__ribbon">{game.ribbon}</div>}
    <GameLogo game={game} studio={studio} />
    {game.tickers && game.tickers.has_jackpot_tickers && (
      <JackpotTicker jackpotId={game.jackpotId} />
    )}
  </>
);

GameEnabled.defaultProps = {
  studio: null,
};

GameEnabled.propTypes = {
  game: PropTypes.instanceOf(Object).isRequired,
  studio: PropTypes.instanceOf(Object),
};

export default GameEnabled;
