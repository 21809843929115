/* eslint import/prefer-default-export: off */
export const selectJackpot = (state, jackpotId) => (
  state['casino/tickers'].jackpotTickers[jackpotId]
);

export const selectJackpots = (state, jackpotIds) => (
  jackpotIds.map((jackpotId) => state['casino/tickers'].jackpotTickers[jackpotId])
);

export const selectliveCasinoTickers = (state, gameId) => (
  state['casino/tickers'].liveCasinoTickers[gameId]
);

export const selectBaccaratTickers = (state, gameId, useWideTile) => (
  state['casino/tickers'].baccaratTickers[`${gameId}/${useWideTile ? '46' : '21'}`]
);
