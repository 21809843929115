import { createSelector } from 'reselect';

const selectAllCategoriesFilterState = (state) => state['casino/all_categories_filter'];

export const selectFilterGroups = createSelector(
  selectAllCategoriesFilterState,
  (allCategoriesFilterState) => allCategoriesFilterState.filterGroups,
);

export const selectCurrentLobby = createSelector(
  selectAllCategoriesFilterState,
  (allCategoriesFilterState) => allCategoriesFilterState.currentLobby,
);

export const selectSelectedFilterTags = createSelector(
  selectAllCategoriesFilterState,
  (data) => data.selectedFilterTags,
);

export const selectFilterTagsInUrl = createSelector(
  selectAllCategoriesFilterState,
  (data) => data.filterTagsInUrl,
);

export const selectFilterResult = createSelector(
  selectAllCategoriesFilterState,
  (allCategoriesFilterState) => allCategoriesFilterState.filterResult,
);

export const selectFetchingStatus = createSelector(
  selectAllCategoriesFilterState,
  ({ isFetching }) => ({ isFetching }),
);
