import { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { cmsAssetPath } from 'bv';
import { ScrollableContext } from 'bv-contexts';
import { Image } from 'bv-components';

const GameLogoView = ({ game, studio, hasTicker }) => {
  const [className, setClassName] = useState('casino-game__logo');
  const scrollableRef = useContext(ScrollableContext);

  useEffect(() => {
    setClassName(classNames('casino-game__logo', {
      'casino-game__logo--with-ticker': hasTicker,
      'casino-game__logo--solid': game.tickers && game.tickers.has_baccarat_roads_tickers,
    }));
  }, [hasTicker]);

  return (
    <div className={className}>
      <Image
        className="casino-game__logo-image"
        src={cmsAssetPath(studio.logo)}
        webpSrc={cmsAssetPath(studio.webpLogo)}
        alt={game.title}
        scrollableRef={scrollableRef}
        webp={!!studio.webpLogo}
      />
    </div>
  );
};

GameLogoView.propTypes = {
  game: PropTypes.instanceOf(Object).isRequired,
  studio: PropTypes.instanceOf(Object),
  hasTicker: PropTypes.bool,
};

GameLogoView.defaultProps = {
  studio: null,
  hasTicker: false,
};

export default GameLogoView;
