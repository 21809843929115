// When this module is loaded we want to add the casino/favourites reducer to the state
import * as actionCreators from './action_creators';
import * as selectors from './selectors';
import reducer from './reducer';

const { addReducers } = window.reduxState;

addReducers({
  'casino/tracking': reducer,
});

export default {
  actionCreators,
  selectors,
};
