import { forwardRef } from 'react';
import PropTypes from 'prop-types';

const GameVideoPoster = forwardRef(({ className, src, webpSrc }, ref) => (
  <picture className={className}>
    <source srcSet={webpSrc} type="image/webp" />
    <img src={src} ref={ref} alt="" />
  </picture>
));

GameVideoPoster.propTypes = {
  className: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  webpSrc: PropTypes.string.isRequired,
};

export default GameVideoPoster;
