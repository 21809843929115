import { addWithRefCount, removeWithRefCount } from 'bv-helpers/redux';
import { ADD_STUDIOS, REMOVE_STUDIOS } from './action_types';

export const initialState = {};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case ADD_STUDIOS:
      return addWithRefCount(state, action.studios);
    case REMOVE_STUDIOS:
      return removeWithRefCount(state, action.studiosIds);
    default:
      return state;
  }
};
