import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { v as bvVar } from 'bv';
import LiveCasinoRoulette from './components/live_casino_roulette';
import LiveCasinoOtherNumbers from './components/live_casino_other_numbers';
import LiveCasinoNumbersView from './live_casino_numbers_view';

const getInnerComponent = (type) => {
  if (['roulette', 'lroulette'].includes(type)) {
    return LiveCasinoRoulette;
  }
  return LiveCasinoOtherNumbers;
};

const LiveCasinoNumbersContainer = ({ tickers, hasTickers, studio }) => {
  const [numbers, setNumbers] = useState([]);
  const [type, setType] = useState('');

  const setGameType = () => {
    if (tickers[5] && hasTickers.has_roulette_results_tickers) setType('roulette');
    if (tickers[6] && hasTickers.has_lightning_roulette_results_tickers) setType('lroulette');
    if (tickers[9] && hasTickers.has_super_sic_bo_results_tickers) setType('sicbo');
    if (tickers[10] && hasTickers.has_dream_catcher_results_tickers) setType('dreamcatcher');
    if (tickers[11] && hasTickers.has_monopoly_results_tickers) setType('monopoly');
    if (tickers[12] && hasTickers.has_lightning_dice_results_tickers) setType('lightning-dice');
    if (tickers[13] && hasTickers.has_crazy_time_tickers) setType('crazy-time');
  };

  const gameLogoClassName = (
    (bvVar('casinoV2').logos && studio && studio.logo) ? 'casino-game-data--with-logo' : ''
  );

  const parseTicker = (ticker) => {
    if (ticker && ticker.v) {
      return ticker.v.split(',');
    }
    return [];
  };

  const setValues = () => {
    if (type === 'roulette') setNumbers(parseTicker(tickers[5]));
    if (type === 'lroulette') setNumbers(parseTicker(tickers[6]));
    if (type === 'sicbo') setNumbers(parseTicker(tickers[9]));
    if (type === 'dreamcatcher') setNumbers(parseTicker(tickers[10]));
    if (type === 'monopoly') setNumbers(parseTicker(tickers[11]));
    if (type === 'lightning-dice') setNumbers(parseTicker(tickers[12]));
    if (type === 'crazy-time') setNumbers(parseTicker(tickers[13]));
  };

  useEffect(() => {
    setGameType();
    setValues();
  }, [tickers]);

  if (type === '') return null;

  return (
    <LiveCasinoNumbersView
      innerComponent={getInnerComponent(type)}
      logoClassName={gameLogoClassName}
      numbers={numbers}
      type={type}
    />
  );
};

LiveCasinoNumbersContainer.defaultProps = {
  hasTickers: {},
  tickers: {},
  studio: null,
};

LiveCasinoNumbersContainer.propTypes = {
  hasTickers: PropTypes.instanceOf(Object),
  tickers: PropTypes.instanceOf(Object),
  studio: PropTypes.instanceOf(Object),
};

export default LiveCasinoNumbersContainer;
