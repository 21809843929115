import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { selectStudio } from 'casino-selectors/studios';
import { selectliveCasinoTickers } from 'casino-selectors/tickers';
import LiveCasinoBetBehindContainer from './live_casino_bet_behind_container';

const LiveCasinoBetBehindRedux = ({ game: { id: gameId, tickers: hasTickers, studio } }) => (
  <LiveCasinoBetBehindContainer
    studio={useSelector((state) => selectStudio(state, studio))}
    tickers={useSelector((state) => selectliveCasinoTickers(state, gameId))}
    hasTickers={hasTickers}
  />
);

LiveCasinoBetBehindRedux.propTypes = {
  game: PropTypes.instanceOf(Object).isRequired,
};

export default LiveCasinoBetBehindRedux;
