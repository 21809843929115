import { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { throttle, noop } from 'underscore';
import { useInfiniteScroll } from 'bv-hooks';

import GamesListView from './games_list_view';

const SCROLL_NEW_GAMES = 30;

const modalEndReached = (modal) => modal.scrollHeight - modal.scrollTop === modal.clientHeight;

const GamesList = ({
  gameInstances,
  onEndReached,
  inModal,
  className,
  scrollableRef,
  renderGames,
  label,
  labelTag,
  infiniteScroll,
  isRightSidebar,
  ...gameProps
}) => {
  const listRef = useRef(null);
  const [elementsToRender, setElementsToRender] = useState(0);

  const onEndOfElement = () => {
    setElementsToRender(listRef.current.childElementCount + SCROLL_NEW_GAMES);
  };

  useInfiniteScroll(infiniteScroll, onEndOfElement, listRef, gameInstances.length);

  // useEffect: listeners on end reached
  useEffect(() => {
    if (!onEndReached) return noop;

    const list = listRef.current;

    const onScroll = throttle(() => {
      if (inModal ? modalEndReached(scrollableRef.current)
        : (window.innerHeight > list.offsetHeight + list.getBoundingClientRect().top)) {
        onEndReached();
      }
    }, 300);

    const scrollElement = inModal ? scrollableRef.current : window;
    scrollElement.addEventListener('scroll', onScroll);

    return () => { scrollElement.removeEventListener('scroll', onScroll); };
  }, []);

  return (
    <GamesListView
      label={label}
      labelTag={labelTag}
      scrollableRef={scrollableRef}
      renderGames={renderGames}
      listRef={listRef}
      elementsToRender={elementsToRender}
      infiniteScroll={infiniteScroll}
      gameInstances={gameInstances}
      gameProps={gameProps}
      className={className}
      isRightSidebar={isRightSidebar}
    />

  );
};

GamesList.propTypes = {
  games: PropTypes.instanceOf(Array).isRequired,
  gameInstances: PropTypes.instanceOf(Array).isRequired,
  onEndReached: PropTypes.func,
  inModal: PropTypes.bool,
  className: PropTypes.string,
  scrollableRef: PropTypes.instanceOf(Object),
  renderGames: PropTypes.func,
  label: PropTypes.string,
  labelTag: PropTypes.string,
  infiniteScroll: PropTypes.bool,
  isRightSidebar: PropTypes.bool,
};

GamesList.defaultProps = {
  onEndReached: null,
  inModal: false,
  className: 'casino-games__all',
  scrollableRef: null,
  label: null,
  labelTag: 'h2',
  infiniteScroll: false,
  renderGames: null,
  isRightSidebar: false,
};

export default GamesList;
