import { useState, useEffect, useRef } from 'react';
import { format } from 'bv-helpers/number';

export default (ticker) => {
  const [jackpot, setJackpot] = useState(0);
  const startTime = useRef(null);
  const startVal = useRef(0);
  const upms = useRef(0);
  const rafRef = useRef();

  const count = (timestamp) => {
    if (!startTime.current) { startTime.current = timestamp; }
    const frameVal = startVal.current + ((timestamp - startTime.current) * upms.current);
    setJackpot(frameVal);
    rafRef.current = requestAnimationFrame(count);
  };

  const setCounter = () => {
    cancelAnimationFrame(rafRef.current);
    startVal.current = parseFloat(ticker.v);
    setJackpot(startVal.current);
    startTime.current = null;
    upms.current = ticker.c.ups / 1000;
    if (ticker.c.ups > 0) rafRef.current = requestAnimationFrame(count);
  };

  useEffect(() => {
    if (ticker) setCounter();
    return () => cancelAnimationFrame(rafRef.current);
  }, [ticker]);

  return format(jackpot.toFixed(2));
};
