import PropTypes from 'prop-types';
import classNames from 'classnames';

const BingoJackpot = ({ jackpot, jackpotPrefix }) => {
  const scrollJackpot = jackpot.length > 11;

  const jackpotClassName = classNames('casino-game__bingo-info-jackpot', {
    'is-scrolling': scrollJackpot,
  });

  return (
    <span className={jackpotClassName}>
      <span className="casino-game__bingo-info-icon" />

      {scrollJackpot ? (
        <div className="casino-game__bingo-jackpot-scroll-wrapper">
          <div className="casino-game__bingo-jackpot-scroll">
            <span className="c-symbol c-preffix">{jackpotPrefix}</span>
            {jackpot}
          </div>
        </div>
      ) : (
        <>
          <span className="c-symbol c-preffix">{jackpotPrefix}</span>
          {jackpot}
        </>
      )}
    </span>
  );
};

BingoJackpot.propTypes = {
  jackpot: PropTypes.instanceOf(String),
  jackpotPrefix: PropTypes.instanceOf(String),
};

BingoJackpot.defaultProps = {
  jackpot: '',
  jackpotPrefix: '',
};

export default BingoJackpot;
