import { postFavoriteGame, deleteFavoriteGame, fetchFavoriteGames } from 'CasinoGlobal/components/game/api';
import { fetchComponentsJson } from 'CasinoV2/api';
import { updateComponent } from 'CasinoV2/ducks/casino_store';
import { selectComponent } from 'CasinoV2/selectors/casino_selector';
import { normaliseComponent } from 'CasinoV2/normalisers';
import {
  addFavorite, removeFavorite, fetchFavorites, addFavorites,
} from './action_creators';
import { selectFetchingStatus } from './selectors';

const refreshFavorites = () => (dispatch) => (
  fetchComponentsJson('favorites')
    .then((data) => data.components)
    .then((components) => components.forEach(
      (component) => dispatch(updateComponent(normaliseComponent(component)))),
    )
);

export const addFavoriteGame = (gameId) => (dispatch, getState) => {
  const state = getState();
  const isFavoritesPage = state?.casinoStore && !!selectComponent(state, { id: -1 });

  dispatch(addFavorite(gameId));
  postFavoriteGame(gameId);
  if (isFavoritesPage) {
    dispatch(refreshFavorites());
  }
};

export const removeFavoriteGame = (gameId) => (dispatch) => {
  dispatch(removeFavorite(gameId));
  deleteFavoriteGame(gameId);
};

export const getFavoriteGames = () => (dispatch, getState) => {
  const state = getState();
  const { isFetching, hasFetched } = selectFetchingStatus(state);

  if (!isFetching && !hasFetched) {
    dispatch(fetchFavorites());

    fetchFavoriteGames()
      .then((data) => data.baseGameIds)
      .then((baseGameIds) => dispatch(addFavorites(baseGameIds)));
  }
};
