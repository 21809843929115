import PropTypes from 'prop-types';

import GameLogo from './game_logo';
import LiveCasinoBetBehind from './live_casino_bet_behind';
import LiveCasinoTableInfo from './live_casino_table_info';
import LiveCasinoBaccarat from './live_casino_baccarat';
import LiveCasinoNumbers from './live_casino_numbers';

const LiveCasinoGameEnabled = ({ game }) => (
  <>
    <GameLogo game={game} />
    <LiveCasinoBetBehind game={game} />
    <LiveCasinoBaccarat game={game} />
    <LiveCasinoNumbers game={game} />
    <LiveCasinoTableInfo game={game} />
  </>
);

LiveCasinoGameEnabled.propTypes = {
  game: PropTypes.instanceOf(Object).isRequired,
};

export default LiveCasinoGameEnabled;
