import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { selectStudio } from 'casino-selectors/studios';
import { selectliveCasinoTickers, selectJackpot } from 'casino-selectors/tickers';
import GameLogoContainer from './game_logo_container';

const GameLogoRedux = ({ game }) => {
  const studio = useSelector((state) => selectStudio(state, game.studio));
  const livecasinoTickers = useSelector((state) => selectliveCasinoTickers(state, game.id));
  const jackpotTicker = useSelector((state) => selectJackpot(state, game.jackpotId));

  if (!studio) return null;

  return (
    <GameLogoContainer
      studio={studio}
      game={game}
      livecasinoTickers={livecasinoTickers}
      jackpotTicker={jackpotTicker}
    />
  );
};

GameLogoRedux.propTypes = {
  game: PropTypes.instanceOf(Object).isRequired,
};

export default GameLogoRedux;
