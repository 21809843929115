import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { selectliveCasinoTickers } from 'casino-selectors/tickers';
import LiveCasinoTableInfoContainer from './live_casino_table_info_container';

const LiveCasinoTableInfoRedux = ({ game: { id: gameId, tickers: hasTickers } }) => {
  const tickers = useSelector((state) => selectliveCasinoTickers(state, gameId));

  return tickers && ((tickers[2] && hasTickers.has_player_count_tickers)
    || (tickers[4] && hasTickers.has_seats_taken_out_of_total_tickers)
    || (tickers[7] && hasTickers.has_min_stake_limits_tickers)
    || (tickers[8] && hasTickers.has_max_stake_limits_tickers))
    ? (
      <LiveCasinoTableInfoContainer
        tickers={tickers}
        hasTickers={hasTickers}
      />
    ) : null;
};

LiveCasinoTableInfoRedux.propTypes = {
  game: PropTypes.instanceOf(Object).isRequired,
};

export default LiveCasinoTableInfoRedux;
