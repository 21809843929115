import { locale } from 'bv-i18n';

const { hostname } = window.location;

export const sendPostMessage = (callParent, gameId, parentSessionId, tracking) => {
  const queryParams = new URLSearchParams();

  if (tracking.type) queryParams.append('type', tracking.type);
  if (tracking.path) queryParams.append('path', tracking.path);

  const queryString = queryParams.toString();

  const post = (message) => window.parent.postMessage(JSON.stringify(message), '*');

  switch (callParent) {
    case 'mini-games':
      post({ type: 'OPEN_SLOT', link: `https://${hostname}/${locale()}/components/mini-games/${gameId}/launch?cd=f&parentSessionId=${parentSessionId}&${queryString}` });
      break;
    default:
  }
};
