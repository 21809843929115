import PropTypes from 'prop-types';
import classNames from 'classnames';

const BingoTimerView = ({
  timeRemaining,
  highlight,
}) => {
  const wrapperClass = classNames('casino-game__timer', {
    'casino-game__timer--starting': highlight,
  });

  return (
    <div className={wrapperClass}>
      {timeRemaining !== '' && (
      <span>{timeRemaining}</span>
      )}
    </div>
  );
};

BingoTimerView.propTypes = {
  timeRemaining: PropTypes.instanceOf(String),
  highlight: PropTypes.bool,
};

BingoTimerView.defaultProps = {
  timeRemaining: '',
  highlight: false,
};

export default BingoTimerView;
