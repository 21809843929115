import PropTypes from 'prop-types';
import { ScrollableContext } from 'bv-contexts';

import Game from '../game';

const GamesListView = ({
  label, labelTag, scrollableRef, renderGames, listRef, elementsToRender, infiniteScroll,
  className, gameInstances, gameProps, isRightSidebar,
}) => {
  const LabelTag = labelTag;
  const gamesElements = gameInstances.map((gameInstance, index) => (
    <Game
      gameId={gameInstance.gameId}
      gameInstance={gameInstance}
      key={gameInstance.gameInstanceId}
      isLazyImage={!(isRightSidebar && index <= 2)}
      {...gameProps}
    />
  ));

  return (
    <>
      {label && (<LabelTag className="bvs-header">{label}</LabelTag>)}
      <ScrollableContext.Provider value={scrollableRef}>
        {renderGames ? (
          renderGames((gamesElements))
        ) : (
          <ul className={className} ref={listRef}>
            {infiniteScroll ? gamesElements.slice(0, elementsToRender) : gamesElements}
          </ul>
        )}
      </ScrollableContext.Provider>
    </>
  );
};

GamesListView.propTypes = {
  label: PropTypes.string,
  labelTag: PropTypes.string.isRequired,
  scrollableRef: PropTypes.instanceOf(Object),
  renderGames: PropTypes.func.isRequired,
  listRef: PropTypes.instanceOf(Object).isRequired,
  elementsToRender: PropTypes.number.isRequired,
  infiniteScroll: PropTypes.bool.isRequired,
  className: PropTypes.string,
  gameInstances: PropTypes.instanceOf(Array).isRequired,
  gameProps: PropTypes.instanceOf(Object).isRequired,
  isRightSidebar: PropTypes.bool,
};

GamesListView.defaultProps = {
  className: null,
  label: '',
  scrollableRef: {},
  isRightSidebar: false,
};

export default GamesListView;
