import { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { cmsAssetPath } from 'bv';
import { ScrollableContext } from 'bv-contexts';

import GameView from './game_view';
import GameEnabled from './components/game_enabled';
import GameDisabled from './components/game_disabled';
import LiveCasinoGameEnabled from './components/live_casino_game_enabled';
import BingoGameEnabled from './components/bingo_game_enabled';

const innerComponent = (
  { game: { status, disabledByProvider }, liveCasinoGame, bingoGame },
) => {
  // Status 2 means game is under maintenance in CasinoOne
  if (disabledByProvider || status === 2) return GameDisabled;
  if (liveCasinoGame) return LiveCasinoGameEnabled;
  if (bingoGame) return BingoGameEnabled;

  return GameEnabled;
};

const imageProp = ({
  game: {
    useWideTile, useAnimation, regularAnimatedSvgImage, largeAnimatedSvgImage,
  }, liveCasinoGame, useLargeImages, forceWideTile, isVideoTile, bingoGame,
}) => {
  if (liveCasinoGame) return `${(forceWideTile || useWideTile) ? 'large' : 'regular'}LiveCasinoImage`;
  if (bingoGame) return `${(useLargeImages || useWideTile) ? 'large' : 'regular'}BingoImage`;

  let animationClass = '';

  if (useAnimation && !isVideoTile) {
    if ((regularAnimatedSvgImage && !useLargeImages) || (largeAnimatedSvgImage && useLargeImages)) {
      animationClass = 'AnimatedSvg';
    }
  }

  return `${useLargeImages ? 'large' : 'regular'}${animationClass}Image`;
};

const GameContainer = (props) => {
  const {
    game,
    liveCasinoGame,
    bingoGame,
    useLargeImages,
    category,
    gameSearchCategoryId,
    tracking,
    isVideoTile,
    callParent,
    parentSessionId,
    isLazyImage,
    favoritesHasFetched,
    showFavorite,
  } = props;

  const [imgLoaded, setImgLoaded] = useState(false);
  const [imgError, setImgError] = useState(false);
  const scrollableRef = useContext(ScrollableContext);

  if (!game) return null;

  /** **********
  * Implementation for active storage image changeover from paperclip.
  * Paperclip image objects from dcs will only contain 2x and 3x properties
  * Activestorage image objects will also contain 2x_webp and 3x_webp properties.
  * Foractive storage, webp image is formed here instead of via string replace in webp wrapper
  ********* */
  const imageProperty = imageProp(props);
  const isSvgImage = imageProperty.includes('AnimatedSvg');
  const imageValue = game[imageProperty];
  const hasWebpImages = (
    !isSvgImage && typeof imageValue === 'object' && imageValue !== null && '2x_webp' in imageValue
  );
  const imagePath = !isSvgImage ? cmsAssetPath(game[imageProperty]?.['2x']) : cmsAssetPath(game[imageProperty]);
  const webpPath = hasWebpImages ? cmsAssetPath(game[imageProperty]?.['2x_webp']) : null;
  /** ******** */

  const {
    disabledByProvider,
    useWideTile,
    isFavorite,
  } = game;

  const gameStyle = (game.tileStyle > 0 && !isVideoTile) ? `casino-game--style${game.tileStyle}` : '';

  const className = (liveCasinoGame || bingoGame) ? `${(useWideTile || useLargeImages) ? 'wide' : 'normal'}-tile` : '';

  const largeImage = (liveCasinoGame || bingoGame)
    ? !!((useWideTile || useLargeImages)) : useLargeImages;

  const gameClassName = classNames('casino-game', 'img', {
    'casino-game--disabled': disabledByProvider,
    'casino-game--large': largeImage,
    'casino-game--live-casino': liveCasinoGame,
    'casino-game--bingo-casino': bingoGame,
    [gameStyle]: gameStyle,
    'casino-game--video': isVideoTile,
    'img-lazy': isLazyImage,
    'img-loaded': imgLoaded,
    'img-error': imgError,
    'is-removing': (!isFavorite && favoritesHasFetched),
  });

  const gameTitleClassName = classNames('casino-game-title', {
    'casino-game-title--large': largeImage,
  });

  return (
    <GameView
      className={className}
      game={game}
      innerComponent={innerComponent(props)}
      gameClassName={gameClassName}
      gameTitleClassName={gameTitleClassName}
      imagePath={imagePath}
      webpPath={webpPath}
      category={category}
      tracking={tracking}
      gameSearchCategoryId={gameSearchCategoryId}
      onImgLoad={() => { setImgLoaded(true); }}
      onImgError={() => { setImgError(true); }}
      scrollableRef={scrollableRef}
      isVideoTile={isVideoTile}
      callParent={callParent}
      isSvgImage={isSvgImage}
      parentSessionId={parentSessionId}
      largeImage={largeImage}
      isLazyImage={isLazyImage}
      showFavorite={showFavorite}
    />
  );
};

GameContainer.propTypes = {
  game: PropTypes.instanceOf(Object).isRequired,
  liveCasinoGame: PropTypes.bool,
  bingoGame: PropTypes.bool,
  useLargeImages: PropTypes.bool,
  category: PropTypes.string,
  gameSearchCategoryId: PropTypes.number,
  isVideoTile: PropTypes.bool,
  callParent: PropTypes.string,
  parentSessionId: PropTypes.string,
  tracking: PropTypes.instanceOf(Object).isRequired,
  isLazyImage: PropTypes.bool,
  favoritesHasFetched: PropTypes.bool,
  showFavorite: PropTypes.bool,
};

GameContainer.defaultProps = {
  isVideoTile: false,
  callParent: '',
  parentSessionId: '',
  liveCasinoGame: false,
  bingoGame: false,
  useLargeImages: false,
  gameSearchCategoryId: null,
  category: null,
  isLazyImage: true,
  favoritesHasFetched: true,
  showFavorite: true,
};

export default GameContainer;

// TODO:
// gameSearchCategoryId/category are only needed by the link
// They are component properties and not game ones
// Use component context?
