import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { selectliveCasinoTickers } from 'casino-selectors/tickers';
import BingoTimerContainer from './bingo_timer_container';

const BingoTimerRedux = ({ game: { id: gameId, tickers: hasTickers } }) => {
  const tickers = useSelector((state) => selectliveCasinoTickers(state, gameId));

  return tickers && tickers[14] && hasTickers.has_bingo_tickers
    ? <BingoTimerContainer tickers={tickers} />
    : null;
};

BingoTimerRedux.propTypes = {
  game: PropTypes.instanceOf(Object).isRequired,
};

export default BingoTimerRedux;
