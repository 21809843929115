import * as actionCreators from './action_creators';
import * as selectors from './selectors';
import reducer from './reducer';

const { addReducers } = window.reduxState;

addReducers({
  'casino/all_categories_filter': reducer,
});

export default {
  actionCreators,
  selectors,
};
