import { addMiddleware } from 'redux-dynamic-middlewares';

// When this module is loaded we want to add the casino/games reducer to the state
import * as actionCreators from './action_creators';
import * as selectors from './selectors';
import reducer from './reducer';
import middleware from './middleware';

const { addReducers } = window.reduxState;

addReducers({
  'casino/games': reducer,
});

addMiddleware(
  middleware,
);

export default {
  actionCreators,
  selectors,
};
