import {
  ADD_FAVORITES, ADD_FAVORITE, REMOVE_FAVORITE, FETCH_FAVORITES,
} from './action_types';

export const initialState = {
  gameIds: [],
  hasFetched: false,
  isFetching: false,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case ADD_FAVORITES:
      return {
        ...state,
        gameIds: action.gameIds,
        hasFetched: true,
        isFetching: false,
      };
    case ADD_FAVORITE:
      return { ...state, gameIds: [action.gameId, ...state.gameIds] };
    case REMOVE_FAVORITE:
      return { ...state, gameIds: state.gameIds.filter((id) => id !== action.gameId) };
    case FETCH_FAVORITES:
      return { ...state, isFetching: true, hasFetched: false };
    default:
      return state;
  }
};
