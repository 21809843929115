import { createSelector } from 'reselect';

const selectFavoritesState = (state) => state['casino/favorites'];

export const selectFavorites = createSelector(
  selectFavoritesState,
  (favoritesState) => favoritesState.gameIds || [],
);

export const selectHasFavorites = createSelector(
  selectFavorites,
  (favorites) => !!favorites.length,
);

export const selectFavorite = () => createSelector(
  selectFavorites,
  (_, gameId) => gameId,
  (favorites, gameId) => favorites.includes(gameId),
);

export const selectFetchingStatus = createSelector(
  selectFavoritesState,
  ({ isFetching, hasFetched }) => ({ isFetching, hasFetched }),
);

export const selectHasFetched = createSelector(
  selectFavoritesState,
  (favoritesState) => favoritesState.hasFetched,
);
