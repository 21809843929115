import { t } from 'bv-i18n';

export const formatTimer = (seconds) => {
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const secTrans = t('datetime.time_abbr.second', { count: seconds % 60 });
  const minTrans = t('datetime.time_abbr.minute', { count: minutes % 60 });
  const hrTrans = t('datetime.time_abbr.hour', { count: hours % 24 });
  const dayTrans = t('datetime.time_abbr.day', { count: days });
  return `${(days > 0 ? dayTrans : '')} \
${(hours > 0 ? hrTrans : '')} \
${(minutes > 0 ? minTrans : '')} \
${secTrans}`;
};
