import PropTypes from 'prop-types';

const LiveCasinoBaccaratView = ({ icons, logoClassName }) => {
  const column = (p) => parseInt(p / 6, 10);

  const row = (p) => p % 6;

  const iconType = (i) => {
    // 1-9 - {1-9} Ties at start of shoe
    if (i >= 1 && i <= 9) return '1-9';

    // 10 - Player
    if (i === 10) return '10';

    // 11-19 - Player followed by {1-9} Tie
    if (i >= 11 && i <= 19) return '11-19';

    // 20 - Player with Player Pair
    if (i === 20) return '20';

    // 21-29 - Player with Player Pair followed by {1-9} Tie
    if (i >= 21 && i <= 29) return '21-29';

    // 30 - Player with Banker Pair
    if (i === 30) return '30';

    // 31-39 - Player with Banker Pair followed by {1-9} Tie
    if (i >= 31 && i <= 39) return '31-39';

    // 40 - Player with Player and Banker Pair
    if (i === 40) return '40';

    // 41-49 - Player with Player and Banker Pair followed by {1-9} Tie
    if (i >= 41 && i <= 49) return '41-49';

    if (i === 50) return '50'; // 50 - Banker

    // 51-59 - Banker followed by {1-9} Tie
    if (i >= 51 && i <= 59) return '51-59';

    // 60 - Banker with Player Pair
    if (i === 60) return '60';

    // 61-69 - Banker with Player Pair followed by {1-9} Tie
    if (i >= 61 && i <= 69) return '61-69';

    // 70 - Banker with Banker Pair
    if (i === 70) return '70';

    // 71-79 - Banker with Banker Pair followed by {1-9} Tie
    if (i >= 71 && i <= 79) return '71-79';

    // 80 - Banker with Player and Banker Pair
    if (i === 80) return '80';

    // 81-89 - Banker with Player and Banker Pair followed by {1-9} Tie
    if (i >= 81 && i <= 89) return '81-89';

    // 0 - Empty
    return 0;
  };

  return (
    <div className={`casino-game-data casino-game-data--baccarat ${logoClassName}`}>
      <ul className="casino-game-data__baccarat-list">
        {icons && icons.map((icon) => (
          <li
            key={`${icon.p}-${icon.v}`}
            className={`row-${row(icon.p)} column-${column(icon.p)} baccarat-${iconType(icon.v)}`}
          />
        ))}
      </ul>
    </div>
  );
};

LiveCasinoBaccaratView.propTypes = {
  logoClassName: PropTypes.string,
  icons: PropTypes.instanceOf(Array),
};

LiveCasinoBaccaratView.defaultProps = {
  logoClassName: '',
  icons: '',
};

export default LiveCasinoBaccaratView;
