import PropTypes from 'prop-types';
import GameLogoView from './game_logo_view';

const usedTickerKeys = ['2', '4', '7', '8'];

const GameLogoContainer = ({
  game,
  studio,
  jackpotTicker,
  livecasinoTickers,
}) => {
  const hasTicker = jackpotTicker || (livecasinoTickers
    && Object.keys(livecasinoTickers).some((key) => usedTickerKeys.includes(key)));

  return <GameLogoView studio={studio} game={game} hasTicker={hasTicker} />;
};

GameLogoContainer.defaultProps = {
  studio: null,
  jackpotTicker: null,
  livecasinoTickers: null,
};

GameLogoContainer.propTypes = {
  game: PropTypes.instanceOf(Object).isRequired,
  studio: PropTypes.instanceOf(Object),
  jackpotTicker: PropTypes.instanceOf(Object),
  livecasinoTickers: PropTypes.instanceOf(Object),
};

export default GameLogoContainer;
