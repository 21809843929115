import { normalize } from 'normalizr';
import { pluck } from 'underscore';

import {
  component as componentEntity,
  game as gameEntity,
} from './entities';

// By default normalizr will add the games ids to the component or search
// We are interested in the studios ids as well so we can easily remove them
// When unmounting a component without having to go through the games

export const normaliseComponent = (component) => {
  const { entities, ...rest } = normalize(component, componentEntity);

  return {
    ...rest,
    entities: {
      ...entities,
      components: Object.values(entities.components).reduce((accum, normComponent) => ({
        ...accum,
        [normComponent.id]: {
          ...normComponent,
          payload: {
            ...normComponent.payload,
            studios: pluck(entities.studios, 'id'),
          },
        },
      }), {}),
    },
  };
};

function extractGameInstanceData(searchResults) {
  return searchResults.games && searchResults.games.map((game) => ({
    gameInstanceId: game.gameInstanceId,
    gameId: game.id,
    ribbon: game.ribbon,
    tileStyle: game.tileStyle,
    useAnimation: game.useAnimation,
  }));
}

export const normaliseSearchResults = (searchResults) => {
  const normalized = normalize(searchResults, { games: [gameEntity] });

  return {
    ...normalized,
    result: {
      ...normalized.result,
      gameInstances: extractGameInstanceData(searchResults),
      studios: pluck(normalized.entities.studios, 'id'),
    },
  };
};
