import PropTypes from 'prop-types';
import { t } from 'bv-i18n';

const LiveCasinoBetBehindView = ({ logoClassName }) => (
  <div className={`casino-game-data ${logoClassName}`}>
    <div className="casino-game-data__message">
      {t('javascript.casino.table_full')}
      <span>{t('javascript.casino.bet_behind')}</span>
    </div>
  </div>
);

LiveCasinoBetBehindView.propTypes = {
  logoClassName: PropTypes.string,
};

LiveCasinoBetBehindView.defaultProps = {
  logoClassName: '',
};

export default LiveCasinoBetBehindView;
