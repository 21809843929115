import { useRef } from 'react';
import PropTypes from 'prop-types';

import { Carousel, Icon } from 'bv-components';
import GamesList from '../games_list';

const GamesSlider = ({
  label,
  icon,
  id,
  isVideoTile,
  ...gamesListProps
}) => {
  const scrollableRef = useRef(null);

  return (
    <>
      <h2 className="bvs-header bvs-ellipsis">
        {icon && <Icon id={icon} big type="casino-on-home" />}
        {label}
      </h2>
      <GamesList
        {...gamesListProps}
        isVideoTile={isVideoTile}
        scrollableRef={scrollableRef}
        renderGames={(gamesElements) => (
          <Carousel
            scrollStep={284}
            ref={scrollableRef}
            sliderId={`slider-${id}`}
            largeArrows
            sliderClassName="casino-games__scroller"
            sliderTagName="ul"
            infinite
            displayFixedArrows={false}
          >
            {gamesElements}
          </Carousel>
        )}
      />
    </>
  );
};

GamesSlider.propTypes = {
  label: PropTypes.string,
  icon: PropTypes.string,
  id: PropTypes.node.isRequired,
  isVideoTile: PropTypes.bool,
};

GamesSlider.defaultProps = {
  label: null,
  icon: null,
  isVideoTile: false,
};

export default GamesSlider;
