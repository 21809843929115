import { useState, useEffect } from 'react';
import { v as bvVar } from 'bv';
import PropTypes from 'prop-types';
import LiveCasinoBetBehindView from './live_casino_bet_behind_view';

const LiveCasinoBetBehindContainer = ({ tickers, hasTickers, studio }) => {
  const [display, setDisplay] = useState(false);

  const isFull = () => {
    if (hasTickers.has_seats_taken_out_of_total_tickers && tickers[4]) {
      const values = tickers[4].v.split('/');
      setDisplay(values[0] === values[1]);
    } else {
      setDisplay(false);
    }
  };

  const gameLogoClassName = (
    (bvVar('casinoV2').logos && studio && studio.logo) ? 'casino-game-data--with-logo' : ''
  );

  useEffect(() => {
    isFull();
  }, [tickers]);

  if (!display) {
    return null;
  }

  return (
    <LiveCasinoBetBehindView
      logoClassName={gameLogoClassName}
    />
  );
};

LiveCasinoBetBehindContainer.defaultProps = {
  hasTickers: {},
  tickers: {},
  studio: null,
};

LiveCasinoBetBehindContainer.propTypes = {
  hasTickers: PropTypes.instanceOf(Object),
  tickers: PropTypes.instanceOf(Object),
  studio: PropTypes.instanceOf(Object),
};

export default LiveCasinoBetBehindContainer;
