import PropTypes from 'prop-types';
import { useJackpotAnimation } from '../../hooks';

const BingoProgressiveJackpot = ({ ticker }) => {
  const value = useJackpotAnimation(ticker);

  return (
    <span className="casino-game__bingo-info-jackpot">
      <span className="casino-game__bingo-info-icon" />
      <span className="c-symbol c-preffix">{ticker.c.cs}</span>
      {value}
    </span>
  );
};

BingoProgressiveJackpot.propTypes = {
  ticker: PropTypes.instanceOf(Object).isRequired,
};

export default BingoProgressiveJackpot;
