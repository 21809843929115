import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useJackpotAnimation } from '../../hooks';

const MultiJackpotElement = ({ ticker }) => {
  const value = useJackpotAnimation(ticker);

  if (!value) return null;

  const wrapperClass = classNames('casino-game__multi-jackpot', {
    'casino-game__multi-jackpot--maxi': ticker?.c?.dpt === '1',
    'casino-game__multi-jackpot--midi': ticker?.c?.dpt === '2',
    'casino-game__multi-jackpot--mini': ticker?.c?.dpt === '3',
  });

  return (
    <div className={wrapperClass}>
      <span className="casino-game__multi-jackpot-icon" />
      <span className="c-symbol c-preffix">{ticker?.c?.cs}</span>
      {value}
    </div>
  );
};

MultiJackpotElement.propTypes = {
  ticker: PropTypes.instanceOf(Object).isRequired,
};

export default MultiJackpotElement;
