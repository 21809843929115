import { ADD_TRACKING } from './action_types';

export const initialState = {};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case ADD_TRACKING:
      return action.trackingDetails;
    default:
      return state;
  }
};
