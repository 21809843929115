import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useHistory } from 'react-router';
import { Link } from 'bv-components';

import { locale } from 'bv-i18n';
import { addRecentSearch } from 'bv-helpers/recent_search_results_cookie';
import { dataLayer } from 'bv-helpers';
import { useFeature, useLoggedIn } from 'bv-hooks';
import { accountNumber } from 'bv-helpers/session';
import {
  getGameLaunchHref, getGameInfoHref,
} from 'CasinoGlobal/helpers';
import { getSlug } from 'CasinoV2/helpers/config';
import getSearchTerm from 'CasinoV2/helpers/get_search_term';
import { useDispatch } from 'react-redux';
import { addTracking } from 'CasinoGlobal/redux/tracking/action_creators';

const { store } = window.reduxState;

const GameLink = ({
  game,
  children,
  category,
  tracking,
}) => {
  const { pathname, search } = useLocation();
  const history = useHistory();
  const searchTerm = getSearchTerm(search) || '';
  const className = 'casino-game-wrapper';
  const loggedIn = useLoggedIn();
  const [casinoLandingPage] = useFeature('casino_landing_pages');
  const dispatch = useDispatch();

  const url = useMemo(() => () => ((loggedIn || !casinoLandingPage)
    ? getGameLaunchHref(game, getSlug())
    : getGameInfoHref(game)), [loggedIn, casinoLandingPage, game]);

  if (game.disabledByProvider) {
    return (
      <div className={className}>
        {children}
      </div>
    );
  }

  const onGameClick = (e) => {
    e.preventDefault();
    const { casinoStore = {} } = store.getState();
    if (searchTerm) {
      addRecentSearch('CASINO', searchTerm);
      const component = pathname.startsWith('/search') ? 'modal' : 'casino';
      const { games, totalEntries } = casinoStore.search[component];
      dataLayer.push({
        event: 'Search Result Click',
        Query: searchTerm,
        Search_Product: 'CASINO',
        Search_Source: component,
        Active_Tab: null,
        Result_Count: totalEntries,
        Click_Index: games.indexOf(game.id),
        Result_URL: `/${locale()}${url}`,
      });
    } else if (loggedIn) {
      const { categoryPage = { components: [] }, components = [] } = casinoStore;
      const visibleComponents = categoryPage.components.map((id) => components[id])
        .filter((component) => component && component.payload.games?.length !== 0);
      const component = visibleComponents
        .find(({ payload }) => payload.category === category && payload.games?.includes(game.id));

      dataLayer.push({
        event: 'evt_game_launch',
        game_id: game.id,
        game_name: game.title,
        launch_section_name: category,
        launch_section_position: visibleComponents.indexOf(component),
        launch_game_position: component ? component.payload.games.indexOf(game.id) : null,
        account_number: accountNumber(),
      });
    }
    dispatch(addTracking({ ...tracking, searchTerm }));
    history.push(url());
  };

  return (
    <Link
      className="casino-game-wrapper"
      to={url()}
      data-react="true"
      onClick={onGameClick}
      aria-label={game.title || 'Game'}
    >
      { children }
    </Link>
  );
};

GameLink.propTypes = {
  game: PropTypes.instanceOf(Object).isRequired,
  children: PropTypes.instanceOf(Object).isRequired,
  category: PropTypes.string,
  tracking: PropTypes.instanceOf(Object).isRequired,
};

GameLink.defaultProps = {
  category: null,
};

export default GameLink;
