export const TOGGLE_SUPPLIER = 'casino/TOGGLE_SUPPLIER';
export const SET_CATALOG = 'casino/SET_CATALOG';
export const SET_SEARCH_RESULT = 'casino/SET_SEARCH_RESULT';
export const SET_FILTERED_GAMES = 'casino/SET_FILTERED_GAMES';
export const SET_COMPONENT = 'casino/SET_COMPONENT';
export const UPDATE_COMPONENT = 'casino/UPDATE_COMPONENT';
const SEARCH_INIT = 'casino/SEARCH_INIT';
export const RESET_SEARCH = 'casino/RESET_SEARCH';
export const RESET_COMPONENTS = 'casino/RESET_COMPONENTS';
const RESET_FILTERS = 'casino/RESET_FILTERS';
const FETCH_CATALOG_INIT = 'casino/FETCH_CATALOG_INIT';
export const FETCH_COMPONENTS_INIT = 'casino/FETCH_COMPONENTS_INIT';
const RESET_CASINO = 'casino/RESET_CASINO';
const SET_COMPONENTS_SUCCESS = 'casino/SET_COMPONENTS_SUCCESS';
const FETCH_FILTERED_GAMES = 'casino/FETCH_FILTERED_GAMES';
const RESET_FILTERED_GAMES = 'casino/RESET_FILTERED_GAMES';

export const setCatalog = (catalog) => ({
  type: SET_CATALOG,
  catalog,
});

export const toggleSupplier = (supplier) => ({
  type: TOGGLE_SUPPLIER,
  supplier,
});

export const updateComponent = (normalisedComponent) => ({
  type: UPDATE_COMPONENT,
  normalisedComponent,
});

export const setComponent = (normalisedComponent) => ({
  type: SET_COMPONENT,
  normalisedComponent,
});

export const searchInit = (component) => () => ({
  type: SEARCH_INIT,
  component,
});

export const setSearchResult = (component) => (normalisedItems) => ({
  type: SET_SEARCH_RESULT,
  normalisedItems,
  component,
});

export const setFilteredGames = (component) => (normalisedItems) => ({
  type: SET_FILTERED_GAMES,
  normalisedItems,
  component,
});

export const resetSearch = (component) => () => ({
  type: RESET_SEARCH,
  component,
});

export const resetComponents = () => ({
  type: RESET_COMPONENTS,
});

export const resetFilters = () => ({
  type: RESET_FILTERS,
});

export const fetchCatalogInit = () => ({
  type: FETCH_CATALOG_INIT,
});

export const resetCasino = () => ({
  type: RESET_CASINO,
});

export const fetchComponentsInit = () => ({
  type: FETCH_COMPONENTS_INIT,
});

export const setComponentsSuccess = () => ({
  type: SET_COMPONENTS_SUCCESS,
});

export const fetchFilteredGames = () => ({
  type: FETCH_FILTERED_GAMES,
});

export const resetFilteredGames = () => ({
  type: RESET_FILTERED_GAMES,
});

const initialSearchState = {
  fetching: true,
  games: [],
  studios: [],
  nextPage: 1,
};

const initialFilterState = {
  isGamesFetching: false,
  games: [],
  gameInstances: [],
  studios: [],
  nextPage: 1,
  totalEntries: 0,
};

export const initialState = {
  categories: [],
  selectedSuppliers: [],
  suppliersOpts: [],
  components: {},
  gameDetails: {},
  launchDetails: null,
  categoryPage: {
    components: [],
    fetching: true,
  },
  search: {
    modal: { ...initialSearchState },
    casino: { ...initialSearchState },
  },
  filter: { ...initialFilterState },
  fetching: true,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_CATALOG:
      return {
        ...state,
        ...action.catalog,
        fetching: false,
      };
    case TOGGLE_SUPPLIER:
      return {
        ...state,
        selectedSuppliers: state.selectedSuppliers.includes(action.supplier)
          ? state.selectedSuppliers.filter((id) => id !== action.supplier)
          : [...state.selectedSuppliers, action.supplier],
      };
    case UPDATE_COMPONENT:
      return {
        ...state,
        components: {
          ...state.components,
          ...action.normalisedComponent.entities.components,
        },
      };
    case SET_COMPONENT:
      return {
        ...state,
        components: {
          ...state.components,
          ...action.normalisedComponent.entities.components,
        },
        categoryPage: {
          ...state.categoryPage,
          components: [
            ...state.categoryPage.components,
            action.normalisedComponent.result,
          ],
        },
      };
    case SET_COMPONENTS_SUCCESS:
      return {
        ...state,
        categoryPage: {
          ...state.categoryPage,
          fetching: false,
        },
      };
    case SEARCH_INIT:
      return {
        ...state,
        search: {
          ...state.search,
          [action.component]: {
            ...state.search[action.component],
            fetching: true,
          },
        },
      };
    case FETCH_FILTERED_GAMES:
      return {
        ...state,
        filter: {
          ...state.filter,
          isGamesFetching: true,
        },
      };
    case SET_SEARCH_RESULT:
    {
      const { normalisedItems: { result: search }, component } = action;

      return {
        ...state,
        search: {
          ...state.search,
          [component]: {
            ...search,
            games: [
              ...state.search[component].games,
              ...(search.games || []),
            ],
            gameInstances: [
              ...(state.search[component].gameInstances || []),
              ...(search.gameInstances || []),
            ],
            studios: [
              ...state.search[component].studios,
              ...(search.studios || []),
            ],
            fetching: false,
          },
        },
      };
    }
    case SET_FILTERED_GAMES:
    {
      const { normalisedItems: { result: filter } } = action;

      return {
        ...state,
        filter: {
          ...state.filter,
          games: [
            ...state.filter.games,
            ...(filter.games || []),
          ],
          gameInstances: [
            ...state.filter.gameInstances,
            ...(filter.gameInstances || []),
          ],
          nextPage: filter.nextPage,
          studios: [
            ...state.filter.studios,
            ...(filter.studios || []),
          ],
          isGamesFetching: false,
          totalEntries: filter.totalEntries,
        },
      };
    }
    case RESET_FILTERED_GAMES:
      return {
        ...state,
        filter: { ...initialFilterState },
      };
    case RESET_SEARCH:
      return {
        ...state,
        search: {
          ...state.search,
          [action.component]: { ...initialSearchState },
        },
      };
    case RESET_COMPONENTS:
      return {
        ...state,
        components: {},
        categoryPage: initialState.categoryPage,
      };
    case RESET_FILTERS:
      return {
        ...state,
        selectedSuppliers: [],
      };
    case FETCH_CATALOG_INIT:
      return {
        ...state,
        fetching: true,
      };
    case FETCH_COMPONENTS_INIT:
      return {
        ...state,
        categoryPage: initialState.categoryPage,
        components: {},
      };
    case RESET_CASINO:
      return {
        ...initialState,
        components: state.components,
        search: state.search,
        categoryPage: state.categoryPage,
      };
    default:
      return state;
  }
};
