import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { format } from 'bv-helpers/number';
import LiveCasinoTableInfoView from './live_casino_table_info_view';

const decimalVal = (dec) => (dec % 1 > 0 ? 2 : 0);

const LiveCasinoTableInfoContainer = ({ tickers, hasTickers }) => {
  const [minstake, setMinstake] = useState('');
  const [maxstake, setMaxstake] = useState('');
  const [minprefix, setMinprefix] = useState('');
  const [maxprefix, setMaxprefix] = useState('');
  const [seats, setSeats] = useState('');

  const setValue = () => {
    if (hasTickers.has_player_count_tickers && tickers[2]) {
      setSeats(tickers[2].v);
    }
    if (hasTickers.has_seats_taken_out_of_total_tickers && tickers[4]) {
      setSeats(tickers[4].v);
    }
    if (hasTickers.has_min_stake_limits_tickers && tickers[7]) {
      setMinprefix(tickers[7].cs);
      const value = parseFloat(tickers[7].v);
      const formattedValue = format(
        value.toFixed(decimalVal(value)),
      );
      setMinstake(formattedValue);
    }
    if (hasTickers.has_max_stake_limits_tickers && tickers[8]) {
      setMaxprefix(tickers[8].cs);
      const value = parseFloat(tickers[8].v);
      const formattedValue = format(
        value.toFixed(decimalVal(value)),
      );
      setMaxstake(formattedValue);
    }
  };

  useEffect(() => {
    setValue();
  }, [tickers]);

  return (
    <LiveCasinoTableInfoView
      minprefix={minprefix}
      minstake={minstake}
      maxprefix={maxprefix}
      maxstake={maxstake}
      seats={seats}
    />
  );
};

LiveCasinoTableInfoContainer.defaultProps = {
  hasTickers: {},
  tickers: {},
};

LiveCasinoTableInfoContainer.propTypes = {
  hasTickers: PropTypes.instanceOf(Object),
  tickers: PropTypes.instanceOf(Object),
};

export default LiveCasinoTableInfoContainer;
