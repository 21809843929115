// import React from 'react';
import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { cmsAssetPath, isDesktop } from 'bv';
import { VideoPlayer } from 'bv-components';
import GameVideoPoster from './game_video_poster';

const videoInViewport = (element, scrollableRef) => {
  const {
    top, left, right, bottom,
  } = element.getBoundingClientRect();

  const { right: parentRight, left: parentLeft } = scrollableRef && scrollableRef.current
    ? scrollableRef.current.getBoundingClientRect()
    : { right: window.innerWidth, left: 0 };

  // This function can be refined, left being > 0 does not mean it's visible
  // If parent bounding left is positive and there are elements scrolled to the left
  // Also done assuming our cases, scrollableRef only for horizontal lists

  return (
    top >= 0
    && left >= 0
    && bottom <= window.innerHeight
    && left >= parentLeft
    && right <= parentRight
  );
};

const videoListenersByScrollable = new Map();
const videoHandlersByScrollable = new Map();

const videoLazyLoadHandler = (scrollable) => {
  if (!videoHandlersByScrollable.has(scrollable)) {
    videoHandlersByScrollable.set(scrollable, () => {
      const listeners = videoListenersByScrollable.get(scrollable);
      listeners.forEach((listener) => { listener(); });
    });
  }

  return videoHandlersByScrollable.get(scrollable);
};

// Add event listenƒers for scrollables
// Create eventListener only if it does not exist
const videoAddListeners = (scrollables, onScroll, scrollEvent) => {
  scrollables.forEach((scrollable) => {
    if (!videoListenersByScrollable.has(scrollable)) {
      videoListenersByScrollable.set(scrollable, []);
      scrollable.addEventListener(scrollEvent, videoLazyLoadHandler(scrollable));
    }

    videoListenersByScrollable.get(scrollable).push(onScroll);
  });
};

const videoRemoveListeners = (scrollables, onScroll, scrollEvent) => {
  scrollables.forEach((scrollable) => {
    if (!videoListenersByScrollable.has(scrollable)) return;

    const listeners = videoListenersByScrollable.get(scrollable);

    const index = listeners.indexOf(onScroll);
    if (index !== -1) { listeners.splice(index, 1); }

    if (!listeners.length) {
      scrollable.removeEventListener(scrollEvent, videoLazyLoadHandler(scrollable));
      videoListenersByScrollable.delete(scrollable);
    }
  });
};

const GameVideoView = ({
  game,
  scrollableRef,
}) => {
  const ref = useRef();
  const videoPosterRef = useRef();
  const [showPoster, setShowPoster] = useState(true);
  const [error, setError] = useState(false);
  let shouldPlayVideo;
  let shouldDelayVideo;

  useEffect(() => {
    const handleVideo = () => {
      if (shouldPlayVideo && shouldDelayVideo) {
        setTimeout(() => {
          shouldDelayVideo = false;

          handleVideo();
        }, 700);
      }

      if (shouldPlayVideo && !shouldDelayVideo) {
        setShowPoster(false);
        ref.current.play();
      }
    };

    const playVideo = () => {
      shouldPlayVideo = true;
      shouldDelayVideo = true;
      handleVideo();
    };

    const stopVideo = () => {
      shouldPlayVideo = false;
      shouldDelayVideo = true;
      ref.current.pause();
    };

    // Desktop play on hover
    if (isDesktop()) {
      const handleVideoHover = () => {
        if (videoInViewport(ref.current, scrollableRef)) {
          playVideo();
        }
      };

      const handleStopVideoHover = () => {
        stopVideo();
      };

      ref.current.addEventListener('mouseenter', handleVideoHover);
      ref.current.addEventListener('mouseleave', handleStopVideoHover);
      videoPosterRef.current.addEventListener('mouseenter', handleVideoHover);
      videoPosterRef.current.addEventListener('mouseleave', handleStopVideoHover);

      return () => {
        ref.current?.removeEventListener('mouseenter', handleVideoHover);
        ref.current?.removeEventListener('mouseouot', handleStopVideoHover);
        videoPosterRef.current?.removeEventListener('mouseenter', handleVideoHover);
        videoPosterRef.current?.removeEventListener('mouseouot', handleStopVideoHover);
      };
    }

    // Mobile play on scroll and fully visible
    // We want scroll listeners always, so we can play/stop the video accordingly
    const scrollables = [
      window,
      scrollableRef && scrollableRef.current,
    ].filter(Boolean);

    const handleVideoScrollVisibility = () => {
      if (videoInViewport(ref.current, scrollableRef)) {
        playVideo();
      } else {
        stopVideo();
      }
    };

    handleVideoScrollVisibility();

    videoAddListeners(scrollables, handleVideoScrollVisibility, 'scroll');

    return () => { videoRemoveListeners(scrollables, handleVideoScrollVisibility, 'scroll'); };
  }, []);

  const onError = () => {
    setError(true);
  };

  return (
    <>
      <VideoPlayer
        url={cmsAssetPath(game.video)}
        opts={{
          ref,
          width: 272,
          height: 272,
          type: 'video/mp4',
          loop: true,
          controls: false,
          muted: true,
          playsInline: true,
          onError,
        }}
      />
      <GameVideoPoster
        src={cmsAssetPath(game.videoFallback?.default)}
        webpSrc={cmsAssetPath(game.videoFallback?.webp)}
        ref={videoPosterRef}
        className={classnames({ hidden: error ? false : !showPoster })}
      />
    </>
  );
};

GameVideoView.propTypes = {
  game: PropTypes.instanceOf(Object).isRequired,
  scrollableRef: PropTypes.instanceOf(Object),
};

GameVideoView.defaultProps = {
  scrollableRef: null,
};

export default GameVideoView;
