import { compose, property } from 'underscore';

export const casinoDomain = (state) => state.casinoStore;
const fromCasino = (prop) => compose(property(prop), casinoDomain);

export const selectCatalogCategories = fromCasino('categories');
export const selectSuppliers = fromCasino('suppliersOpts');
export const selectSelectedSuppliers = fromCasino('selectedSuppliers');
export const selectComponents = fromCasino(['categoryPage', 'components']);

export const selectComponent = (state, props) => (
  casinoDomain(state).components[props.id]
);

export const selectSearch = (component) => fromCasino(['search', component]);
export const selectFilteredGames = fromCasino('filter');
export const selectIsFetching = fromCasino('fetching');
export const selectIsFetchingComponents = fromCasino(['categoryPage', 'fetching']);
export const selectBannersLocation = fromCasino('bannersLocation');
export const selectShowSuppliersFilter = fromCasino('showSuppliersFilter');
export const selectShowSearch = fromCasino('showSearch');
export const selectShowNavigation = fromCasino('showNavigation');
export const selectNormComponents = fromCasino('components');

export const selectSeoEntity = (state) => {
  if (
    !state.seo
    || state.seo.fetching
    || state.seo.isDefault
  ) return {};
  return state.seo;
};
