import PropTypes from 'prop-types';
import { sendPostMessage } from 'CasinoGlobal/helpers';

const GamePostMessage = ({
  game,
  children,
  callParent,
  parentSessionId,
  tracking,
}) => {
  const handleOnClick = () => (
    !game.disabledByProvider
    && sendPostMessage(callParent, game.id, parentSessionId, tracking)
  );

  return (
    <div
      className="casino-game-wrapper bvs-link"
      onClick={handleOnClick}
    >
      {children}
    </div>
  );
};

GamePostMessage.propTypes = {
  game: PropTypes.instanceOf(Object).isRequired,
  children: PropTypes.instanceOf(Object).isRequired,
  callParent: PropTypes.string,
  parentSessionId: PropTypes.string,
  tracking: PropTypes.instanceOf(Object).isRequired,
};

GamePostMessage.defaultProps = {
  callParent: '',
  parentSessionId: '',
};

export default GamePostMessage;
