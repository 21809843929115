import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { selectBaccaratTickers } from 'casino-selectors/tickers';
import { selectStudio } from 'casino-selectors/studios';
import LiveCasinoBaccaratContainer from './live_casino_baccarat_container';

const LiveCasinoBaccaratRedux = ({
  game: {
    id: gameId,
    tickers: hasTickers,
    studio,
    useWideTile,
  },
}) => (
  <LiveCasinoBaccaratContainer
    tickers={useSelector((state) => selectBaccaratTickers(state, gameId, useWideTile))}
    studio={useSelector((state) => selectStudio(state, studio))}
    hasTickers={hasTickers}
  />
);

LiveCasinoBaccaratRedux.propTypes = {
  game: PropTypes.instanceOf(Object).isRequired,
};

export default LiveCasinoBaccaratRedux;
