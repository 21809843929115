import { v as bvVar } from 'bv';
import PropTypes from 'prop-types';
import LiveCasinoBaccaratView from './live_casino_baccarat_view';

const LiveCasinoBaccaratContainer = ({ tickers, hasTickers, studio }) => {
  const gameLogoClassName = (
    (bvVar('casinoV2').logos && studio && studio.logo) ? 'casino-game-data--with-logo' : ''
  );

  return tickers && tickers.length && hasTickers.has_baccarat_roads_tickers ? (
    <LiveCasinoBaccaratView
      logoClassName={gameLogoClassName}
      icons={tickers}
    />
  ) : null;
};

LiveCasinoBaccaratContainer.defaultProps = {
  hasTickers: {},
  tickers: null,
  studio: null,
};

LiveCasinoBaccaratContainer.propTypes = {
  hasTickers: PropTypes.instanceOf(Object),
  tickers: PropTypes.instanceOf(Array),
  studio: PropTypes.instanceOf(Object),
};

export default LiveCasinoBaccaratContainer;
