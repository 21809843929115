import { registerHandler, unregisterHandler } from 'event-bus';
import { setBaccaratTicker } from 'CasinoGlobal/redux/tickers/action_creators';

const { store } = window.reduxState;
const baccaratHandlers = {};

const baccaratTickerHandler = (id) => {
  baccaratHandlers[id] = baccaratHandlers[id] || ((err, { body: data }) => {
    if (data) {
      store.dispatch(setBaccaratTicker({ id, ticker: data }));
    }
  });

  return baccaratHandlers[id];
};

const withTickerId = (fn) => (tickerId) => {
  fn(
    `/c/casinotickersbaccarat/${tickerId}`,
    baccaratTickerHandler(tickerId),
  );
};

const forEachGame = (fn) => (ids) => {
  ids.forEach(fn);
};

export const registerBaccaratTickers = forEachGame(withTickerId(registerHandler));
export const unregisterBaccaratTickers = forEachGame(withTickerId(unregisterHandler));
