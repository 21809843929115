import { schema } from 'normalizr';

const processStrategy = (value) => ({
  ...value,
  payload: {
    ...value.payload,
    gameInstances: value.payload.games && value.payload.games.map((game) => ({
      gameInstanceId: game.gameInstanceId,
      gameId: game.id,
      ribbon: game.ribbon,
      tileStyle: game.tileStyle,
      useAnimation: game.useAnimation,
    })),
  },
});

const studio = new schema.Entity('studios');

export const game = new schema.Entity('games', {
  studio,
}, {
  processStrategy: ({
    ribbon, tileStyle, useAnimation, ...rest
  }) => ({ ...rest }),
});

export const component = new schema.Entity('components', {
  payload: {
    games: [game],
  },
}, {
  processStrategy,
});
